import { useShowModal } from '@oneclickdata/components'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { save } from '../modules/profile'
import { useProfile } from '../queryHooks'
import analytics from '../services/analytics'

const useContinue = () => {
  const showModal = useShowModal()
  const { data: profile } = useProfile()
  const dispatch = useDispatch()

  const onContinue = useCallback(async () => {
    showModal({ isOpen: false, withLoading: false })
    analytics.logEvent('buttonClick', { currentScreen: 'TOS_Acknowledged' })
    await dispatch(
      save({
        userId: profile.userId,
        accepted_tos_at: Date.now()
      })
    )
  }, [showModal, dispatch, profile.userId])

  return onContinue
}

export const useShowTosUpdatedModal = async () => {
  const { data: profile } = useProfile()
  const showModal = useShowModal()
  const onContinue = useContinue()
  const dispatch = useDispatch()

  const { accepted_tos_at, tos_last_updated_at } = profile
  useEffect(() => {
    const checkAndShowModal = async () => {
      if (tos_last_updated_at > accepted_tos_at) {
        analytics.logEvent("screenView", {
          currentScreen: "TermsOfServiceUpdatedModal"
        })
        await showModal({
          withLoading: false,
          title: "Policy Update Notification",
          description: `We've made important updates to our [Terms of Service](https://www.oneclickcode.com/terms-of-use) and [Privacy Policy](https://www.oneclickcode.com/privacy-policy) to better serve you and to comply with the latest regulations. By continuing to use our services, you acknowledge that you have read and agree to the new terms.`,
          primaryActionText: "Got It",
          primaryAction: onContinue,


          onClose: () => onContinue(),
          onHide: () => onContinue()
        })
        await dispatch(save({
          userId: profile.userId,
          accepted_tos_at: Date.now()


        }))
      }
    }

    checkAndShowModal()

  }, [accepted_tos_at, dispatch, tos_last_updated_at, showModal, onContinue, profile.userId])
}
