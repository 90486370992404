import React from 'react'
import { Animated, View, Easing } from 'react-native'

const styles = {
  mainView: {
    flexDirection: 'row',
    backgroundColor: 'transparent',
    alignItems: 'center'
  }
}

class Spinner extends React.Component {
  opacityOne = new Animated.Value(0)

  componentDidMount() {
    this.animate()
  }

  componentWillUnmount() {
    this.loop ? this.loop.stop() : (() => null)()
  }

  animate = () => {
    if (this.loop) {
      this.loop.start()
    } else {
      this.loop = Animated.loop(
        Animated.timing(this.opacityOne, {
          toValue: 1,
          duration: 1000,
          easing: Easing.linear,
          useNativeDriver: true
        })
      ).start()
    }
  }

  render() {
    return (
      <View style={styles.mainView}>
        <Animated.Image
          style={{
            overflow: 'visible',
            height: 11,
            width: 42,
            top: 8,
            left: -1,
            opacity: this.opacityOne.interpolate({
              inputRange: [0, 0.25, 0.5, 0.75, 1],
              outputRange: [0.25, 0, 1, 1, 0.5]
            })
          }}
          source={require('../../../images/misc/roofTops@3x.png')}
        />
        <Animated.Image
          style={{
            height: 11,
            width: 42,
            opacity: this.opacityOne.interpolate({
              inputRange: [0, 0.25, 0.5, 0.75, 1],
              outputRange: [0.5, 0.25, 0, 1, 1]
            }),
            overflow: 'visible'
          }}
          source={require('../../../images/misc/roofTops@3x.png')}
        />
        <Animated.Image
          style={{
            height: 11,
            width: 42,
            top: 6,
            left: -10,
            opacity: this.opacityOne.interpolate({
              inputRange: [0, 0.25, 0.5, 0.75, 1],
              outputRange: [1, 0.5, 0.25, 0, 1]
            }),
            overflow: 'visible'
          }}
          source={require('../../../images/misc/roofTops@3x.png')}
        />
      </View>
    )
  }
}

export default Spinner
