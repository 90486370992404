import { useEffect, useState } from 'react'

const defaultBreakpoints = {
  base: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 2560
}

export const useBreakpointValue = (object, breakpoints = defaultBreakpoints) => {
  const [matches, setMatches] = useState({})

  useEffect(() => {
    const localMatches = {}
    const queries = {}
    const listeners = []
    Object.keys(breakpoints).forEach((key) => {
      const mediaQuery = `(min-width: ${breakpoints[key]}px)`
      queries[key] = window.matchMedia(mediaQuery)
      const query = queries[key]
      localMatches[key] = query.matches
      const listener = (event) => {
        const newMatches = { ...localMatches }
        newMatches[key] = event.matches
        setMatches(newMatches)
      }
      query.addEventListener('change', listener)
      listeners.push({ key, listener })
    })
    setMatches(localMatches)

    return () => listeners.forEach(({ key, listener }) => queries[key].removeEventListener('change', listener))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const matched = Object.keys(breakpoints)
    .reverse()
    .find(key => matches[key] && !!object[key])

  return object[matched] || object.base
}
