/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "occapi",
            "endpoint": "https://pjdwyrdweg.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "occ-account-hashes-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-api-keys-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-api-partners-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-climate-details-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-config-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-consumer-teams-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-counters-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-form-lists-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-global-events-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-id-account-associations-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-iecc-rvalues-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-last-user-update-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-muni-details-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-muni-lookup-errors-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-partner-reports-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-permit-calc-logs-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-products-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-profile-aliases-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-profiles-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-project-municipal-data-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-reports-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-sso-domains-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-sso-providers-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-team-invites-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-team-members-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-transactions-dev",
            "region": "us-east-1"
        },
        {
            "tableName": "occ-user-api-keys-dev",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
