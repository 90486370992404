import { Auth } from 'aws-amplify'
import api from '../api'

export const SIGNUP_REQUESTED = 'signup/SIGNUP_REQUESTED'
export const SIGNUP_SUCCESS = 'signup/SIGNUP_SUCCESS'
export const SIGNUP_FAILED = 'signup/SIGNUP_FAILED'
export const CONFIRMATION_REQUESTED = 'signup/CONFIRMATION_REQUESTED'
export const CONFIRMATION_SUCCESS = 'signup/CONFIRMATION_SUCCESS'
export const CONFIRMATION_FAILED = 'signup/CONFIRMATION_FAILED'
export const LOGIN_VERIFICATION = 'signup/LOGIN_VERIFICATION'
export const SET_USER_EMAIL = 'signup/SET_USER_EMAIL'
export const SET_SIGNUP_ACTIVE_SLIDE = 'signup/SET_SIGNUP_ACTIVE_SLIDE'

const initialState = {
  isSigningUp: false,
  isConfirming: false,
  email: null,
  password: null,
  error: null,
  signupSlide: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_REQUESTED:
      return {
        ...state,
        error: null,
        isSigningUp: true
      }
    case SIGNUP_SUCCESS:
      return {
        ...state,
        email: action.email,
        password: action.password,
        error: null,
        isSigningUp: false
      }
    case SIGNUP_FAILED:
      return {
        ...state,
        error: action.error,
        isSigningUp: false
      }
    case CONFIRMATION_REQUESTED:
      return {
        ...state,
        error: null,
        isConfirming: true
      }
    case CONFIRMATION_SUCCESS:
      return {
        ...state,
        error: null,
        password: null,
        isConfirming: false
      }
    case CONFIRMATION_FAILED:
      return {
        ...state,
        error: action.error,
        isConfirming: false
      }
    case LOGIN_VERIFICATION:
      return {
        ...state,
        password: action.password,
        email: action.email
      }
    case SET_SIGNUP_ACTIVE_SLIDE:
      return {
        ...state,
        signupSlide: action.payload
      }
    default:
      return state
  }
}

export const setSignUpActiveSlide = slideIdx => ({ type: SET_SIGNUP_ACTIVE_SLIDE, payload: slideIdx })

export const loginConfirmCode = (password, email) => (dispatch) => {
  dispatch({
    type: LOGIN_VERIFICATION,
    password,
    email
  })
}

export const signup =
  ({ email, password }) =>
  async (dispatch) => {
    dispatch({ type: SIGNUP_REQUESTED })
    try {
      await Auth.signUp({
        password,
        username: email.toLowerCase(),
        attributes: { email: email.toLowerCase() }
      })
      dispatch({ type: SIGNUP_SUCCESS, password, email: email.toLowerCase() })

      return true
    } catch (err) {
      const error = err.message || (typeof err === 'string' ? err : JSON.stringify(err))
      dispatch({ type: SIGNUP_FAILED, error })

      return false
    }
  }

// eslint-disable-next-line no-unused-vars
export const googleFederatedSignup = loginUser => async (dispatch) => {
  const givenName = loginUser?.givenName
  const email = loginUser?.email
  try {
    await api.profile.save(
      {
        source: 'federated',
        ...(givenName && { givenName }),
        ...(email && { email: email.toLowerCase() }),
        doesNotExist: false
      },
      false
    )

    return true
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('failure saving [googleFederatedSignup] profile manager', e)

    return false
  }
}

// all microsoft SSO federated profile generation should auto-populate team_id based on IdP
// eslint-disable-next-line no-unused-vars
export const microsoftFederatedSignup = (loginUser, team_id) => async (dispatch) => {
  const email = loginUser?.email
  try {
    await api.profile.save(
      {
        source: 'federated',
        givenName: loginUser.name,
        name: loginUser.name,
        ...(email && { email: email.toLowerCase() }),
        microsoftId: loginUser.microsoftId,
        doesNotExist: false,
        team_id
      },
      false
    )

    return true
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('failure saving [microsoftFederatedSignup] profile manager', e)

    return false
  }
}

export const confirmSignup =
  ({ username, code }) =>
  async (dispatch) => {
    dispatch({ type: CONFIRMATION_REQUESTED })
    try {
      const result = await Auth.confirmSignUp(username, code)
      dispatch({ type: CONFIRMATION_SUCCESS })

      return result
    } catch (err) {
      const error = err.message || (typeof err === 'string' ? err : JSON.stringify(err))
      dispatch({ type: CONFIRMATION_FAILED, error })

      return error
    }
  }
