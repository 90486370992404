/**
 * Cache object to hold the ETag value.
 * @type {{value: null|string}}
 */
const cache = {
  value: null
}

/**
 * Fetches the current ETag value from the server.
 *
 * @async
 * @returns {Promise<string|null>} The ETag value or null if not available.
 */
const getCurrentValue = async () => {
  try {
    const response = await fetch('/', {
      method: 'HEAD'
    })

    return response.headers.get('etag')
  } catch (e) {
    return null
  }
}

/**
 * Caches the current ETag value.
 *
 * @async
 */
const cacheCurrentValue = async () => {
  cache.value = await getCurrentValue()
}

/**
 * Checks if the cached ETag value is current.
 *
 * @async
 * @returns {Promise<boolean>} True if the cache is current, otherwise false.
 */
const isCacheCurrent = async () => {
  const serverETag = await getCurrentValue()

  return cache.value === serverETag
}

export default {
  cacheCurrentValue,
  isCacheCurrent
}
