import { API, Auth } from 'aws-amplify'
import profileTable from '../profileTable'

const manager = {
  load: async () => {
    try {
      const profile = await profileTable.load()

      return profile
    } catch (err) {
      //
    }
  },

  createAlias: async (body) => {
    const path = '/v2/profile/alias'
    try {
      const response = await API.post('occapi', path, { body })

      return response
    } catch (err) {
      //
    }
  },

  userProfileToCache: async () => {
    try {
      const profile = await manager.load()
      Cache.setItem('user_profile', profile)
    } catch (err) {
      // err
    }
  },

  save: async profile => profileTable.save(profile),

  requestPhoneVerificationCode: async () => {
    await Auth.verifyCurrentUserAttribute('phone_number')

    return { response: 'SUCCESS' }
  },

  submitPhoneVerificationCode: async (code, profile) => {
    await Auth.verifyCurrentUserAttributeSubmit('phone_number', code)
    await manager.save(profile)

    return { response: 'SUCCESS' }
  },

  resendSignupCode: username => Auth.resendSignUp(username),

  sendEmailVerificationCode: () => Auth.verifyCurrentUserAttribute('email'),

  changePassword: async (currentPassword, newPassword) => {
    const user = await Auth.currentUserPoolUser()
    const profile = await profileTable.load()
    const response = new Promise((resolve, reject) => {
      user.changePassword(currentPassword, newPassword, (err, res) => {
        if (err) {
          reject(err)
        } else if (res !== 'SUCCESS') {
          reject(res)
        } else {
          profileTable.save({ ...profile, lastPasswordChange: Date.now() }).catch(() => null)
          resolve(res)
        }
      })
    })

    return response
  },

  onSignupEmailVerified: async () => API.post('occapi', '/profile/signup-email-verified'),

  getUserLimits: async (userId) => {
    const path = '/codeReports/profile/limits'
    const userLimits = API.get('occapi', `${path}?userId=${userId}`)

    return userLimits
  },

  getBlockStatus: async (reason) => {
    const path = '/codeReports/profile/limits/blocked'
    const userLimits = API.get('occapi', `${path}?reason=${reason}`)

    return userLimits
  },

  // for reference: https://docs.amplify.aws/javascript/prev/build-a-backend/auth/manage-user-profile/#pageMain
  changeEmail: async (email) => {
    // Dont let this send to an existing account. Callee should check if email is available first
    const user = await Auth.currentUserPoolUser()

    return Auth.updateUserAttributes(user, { email })
  },

  verifyEmailChange: async (verificationCode) => {
    const result = await Auth.verifyCurrentUserAttributeSubmit('email', verificationCode)
    if (result === 'SUCCESS') {
      await profileTable.updateProfileEmail()

      return true
    }

    return false
  },

  /**
   * Sends UTM parameters to the `/profile/utm` endpoint to track user profile updates.
   * The user ID is automatically added on the backend via middleware.
   *
   * @async
   * @function trackUTM
   * @param {Object} utm_parameters - The UTM parameters for tracking.
   * @param {string} utm_parameters.utm_source - Source of the traffic, e.g., 'google'.
   * @param {string} utm_parameters.utm_medium - Marketing medium, e.g., 'cpc'.
   * @param {string} utm_parameters.utm_campaign - Campaign name, e.g., 'summer_sale'.
   * @param {string} utm_parameters.utm_term - Paid search terms, e.g., 'roofing'.
   * @param {string} utm_parameters.utm_content - Ad content description, e.g., 'ad_123'.
   * @returns {Promise<Object>} Resolves with the response from the API call.
   *
   * @example
   * const utmData = {
   *   utm_source: 'google',
   *   utm_medium: 'cpc',
   *   utm_campaign: 'summer_sale',
   *   utm_term: 'roofing',
   *   utm_content: 'ad_123'
   * }
   * const response = await trackUTM(utmData)
   * console.log(response)
   */
  trackUTM: async (utm_parameters) => {
    const path = '/profile/utm'
    return await API.post('occapi', path, { body: { utm_parameters } })
  }
}

export default manager
window.manager = manager
