import { API, Auth } from 'aws-amplify'

const BASE_PATH = '/codeReports/form-lists'

const manager = {
  get: async (listType) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/${listType}`)

    return apiResponse
  }
}

export default manager
