import React, { createContext, useMemo, useState } from 'react'

export const MOBILE_HEADER_ACTIONS = {
  userMenu: 'USER_MENU',
  backOnce: 'BACK_ONCE',
  back: 'BACK'
}

const MobileHeaderContext = createContext({
  HTMLContentOfTheContext: null
})

export default MobileHeaderContext

export const MobileHeaderProvider = ({ children }) => {
  const [HTMLContentOfTheContext, setMobileHeaderContext] = useState(null)
  const [hideDefaultHeader, setHideDefaultHeader] = useState(false)
  const [mobileHeaderAction, setMobileHeaderAction] = useState(MOBILE_HEADER_ACTIONS.userMenu)
  const [showBackButton, setShowBackButton] = useState(false)

  const mobileHeaderValue = useMemo(
    () => ({
      HTMLContentOfTheContext,
      setMobileHeaderContext,
      hideDefaultHeader,
      mobileHeaderAction,
      setMobileHeaderAction,
      setHideDefaultHeader,
      showBackButton,
      setShowBackButton
    }),
    [HTMLContentOfTheContext, mobileHeaderAction, hideDefaultHeader, showBackButton]
  )

  return <MobileHeaderContext.Provider value={mobileHeaderValue}>{children}</MobileHeaderContext.Provider>
}
