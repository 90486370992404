import React from 'react'
import Flashy from './flashy'
import './newProject.css'

const NewProject = ({ onClick, small, id }) => (
  <Flashy onClick={onClick} id={id}>
    <div
      className="newProject-button"
      style={{
        width: small ? 157 : '100%'
      }}>
      <img
        alt="Plus"
        src={Plus}
        style={{
          paddingRight: 9,
          height: 35
        }}
      />
      <div
        style={{
          width: small ? 59 : '100%',
          fontSize: small ? 12 : 14,
          letterSpacing: small ? 0.86 : 1
        }}>
        NEW PROJECT
      </div>
    </div>
  </Flashy>
)

export default NewProject

const Plus = require('../../images/icons/newProject@3x.png')
