import React from 'react'
import { brand } from '../../utils/CommonStyles'
import Blink from './blink'

const Primary = ({ title = 'title', action, containerStyle, style, to }) => (
  <Blink onClick={action} to={to} style={{ ...styles.primary, ...containerStyle }}>
    <div style={{ ...styles.title, ...style }}>{title}</div>
  </Blink>
)

export default Primary

const styles = {
  primary: {
    display: 'inline-block',
    backgroundColor: brand.color.brightBlue,
    height: '50px',
    paddingRight: '60px',
    paddingLeft: '60px',
    borderRadius: '25px',
    fontSize: '17px',
    letterSpacing: 1,
    fontWeight: '700',
    textTransform: 'uppercase',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    maxWidth: 300
  },
  title: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)'
  }
}
