import { API, Auth } from 'aws-amplify'

const BASE_PATH = '/codeReports/orders'

const manager = {
  estimate: async (params) => {
    const apiResponse = await API.post('occapi', `${BASE_PATH}/estimate`, {
      body: { params }
    })

    return apiResponse
  },

  orderCodeReport: async (details) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/code-report`, {
      body: { details }
    })

    return apiResponse
  },

  orderCodeReportPlan: async (details) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/code-report-plan`, {
      body: { details }
    })

    return apiResponse
  },

  removeCodeReportPlan: async (details) => {
    await Auth.currentCredentials()
    const apiResponse = await API.del('occapi', `${BASE_PATH}/code-report-plan`, {
      body: { details }
    })

    return apiResponse
  },

  reactivateCodeReportPlan: async () => {
    await Auth.currentCredentials()

    const apiResponse = await API.post('occapi', `${BASE_PATH}/code-report-plan/reactivate`)
    return apiResponse
  },

  pauseCodeReportPlan: async ({ durationMonths }) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/code-report-plan/pause`, {
      body: { durationMonths }
    })

    return apiResponse
  },

  getPauseOptions: async ({ count = 3 }) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/code-report-plan/pause/options/${count}`)

    return apiResponse
  },

  resumeCodeReportPlan: async () => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/code-report-plan/resume`)

    return apiResponse
  },

  getCodeReportOption: async () => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/code-report`)

    return apiResponse
  },

  verifyCoupon: async (couponId) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/plan-coupon`, {
      body: {
        couponId
      }
    })

    return apiResponse
  },

  orderAddOn: async (sku, priceId, team_id) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/add-ons`, {
      body: {
        sku,
        team_id,
        priceId
      }
    })

    return apiResponse
  }
}

// eslint-disable-next-line no-underscore-dangle
window._orders = manager

export default manager
