import merge from 'deepmerge'
import { useSelector } from 'react-redux'

const recursiveMerge = (...params) => {
  const items = params.filter(i => i)
  if (items.length === 1) {
    return items[0]
  }
  if (items.length === 0) {
    return items
  }
  const [target, source, ...rest] = items
  const merged = merge(target, source, { arrayMerge: (a, b) => b })

  return recursiveMerge(merged, ...rest)
}

export const useLimits = () => {
  const profile = useSelector(state => state.profile)
  const subscriptionLimits = profile?.subscriptions?.[0]?.limits
  const teamLimits = profile?.team?.limits
  const profileLimits = profile?.limits

  return recursiveMerge(subscriptionLimits, teamLimits, profileLimits)
}
