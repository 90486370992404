import responsiveImage from './responsiveImage'

export const Delete = responsiveImage('Delete.', [
  require('../../images/icons/delete.png'),
  require('../../images/icons/delete@2x.png'),
  require('../../images/icons/delete@3x.png')
])

export const Download = responsiveImage('Print.', [
  require('../../images/icons/download_white.png'),
  require('../../images/icons/download_white@2x.png'),
  require('../../images/icons/download_white@3x.png')
])

export const Email = responsiveImage('Email.', [
  require('../../images/icons/email_white.png'),
  require('../../images/icons/email_white@2x.png'),
  require('../../images/icons/email_white@3x.png')
])

export const Text = responsiveImage('Text.', [
  require('../../images/icons/text_white.png'),
  require('../../images/icons/text_white@2x.png'),
  require('../../images/icons/text_white@3x.png')
])

export const Print = responsiveImage('Print.', [
  require('../../images/icons/print_white.png'),
  require('../../images/icons/print_white@2x.png'),
  require('../../images/icons/print_white@3x.png')
])
