/* eslint-disable no-underscore-dangle */
import { merge } from 'lodash'
import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native-web'
import { SM_SIZE, XS_SIZE } from './Responsiveness'

function getBreakpoint({ width } = Dimensions.get('window')) {
  if (width <= XS_SIZE) {
    return 'xs'
  } else if (width <= SM_SIZE) {
    return 'sm'
  } else if (width <= 1168) {
    return 'lg'
  }

  return 'xl'
}

export function responsive(obj, breakpoint) {
  const sizes = ['xs', 'sm', 'md', 'lg', 'xl']
  const settings = {
    merge: true,
    ...(obj.settings && obj.settings)
  }
  if (settings.merge) {
    let index = 0
    const breakpointIndex = sizes.findIndex(size => size === breakpoint)
    let style = {}
    while (index <= breakpointIndex) {
      style = merge(style, obj[sizes[index++]])
    }

    return style
  }
  let index = sizes.findIndex(size => size === breakpoint)
  let style = obj[breakpoint]
  while (!style) {
    if (index < 0) {
      return null
    }
    style = obj[sizes[--index]] // eslint-disable-line
  }

  return style
}

function useResponsive(object) {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint())
  function handleResize() {
    setBreakpoint(getBreakpoint())
  }
  useEffect(() => {
    Dimensions.addEventListener('change', handleResize)

    return () => {
      Dimensions.removeEventListener('change', handleResize)
    }
  }, [])

  const responsiveHandler = obj => responsive(obj, breakpoint)
  if (object) {
    const _props = responsive(object, breakpoint)
    Object.keys(_props).forEach((key) => {
      responsiveHandler[key] = _props[key]
    })
  }
  responsiveHandler.size = breakpoint

  return responsiveHandler
}

export default useResponsive
