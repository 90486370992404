import { useSelector } from 'react-redux'

export const usePlanUI = () => {
  const profile = useSelector(state => state.profile)
  const subscription = profile?.subscriptions?.[0]

  return {
    ...subscription?.ui,
    sku: subscription?.sku
  }
}
