import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { useEffect, useState } from 'react'

export const QueryDevTools = () => {
  const [areToolsEnabled, setAreToolsEnabled] = useState(false)
  useEffect(() => {
    window.toggleQueryDevTools = () => setAreToolsEnabled(prevState => !prevState)

    return () => delete window.toggleQueryDevTools
  }, [setAreToolsEnabled])

  return areToolsEnabled ? <ReactQueryDevtools initialIsOpen={false} /> : null
}
