export const PLAN_PURCHASE_INPUT_NAMES = {
  addressRequired: 'addressRequired',
  billingAddress: 'billingAddress',
  billingZip: 'billingZip',
  billingCity: 'billingCity',
  creditCardComplete: 'creditCardComplete',
  planVariant: 'planVariant',
  planBilling: 'planBilling',
  stateSelection: 'stateSelection',
  couponCode: 'couponCode',
  newCard: 'newCard'
}

export const PLAN_VARIANTS = {
  oneState: 'one_state',
  national: 'national'
}

export const PLAN_BILLING_OPTIONS = {
  monthly: 'monthly',
  annual: 'annual'
}

export const PLAN_PURCHASE_SCREEN_NAME = 'PlanPurchase'
export const PLAN_SUCCESS_SCREEN_NAME = 'PlanSuccess'
export const ONE_STATE_VARIANT_LABEL = '1 State'
export const NATIONAL_VARIANT_LABEL = '50 States'
