import { StyleSheet } from 'react-native-web'

export const brand = {
  color: {
    activeGreen: '#7FCA17',
    darkGreen: '#009200',
    darkerGreen: '#179408',
    highlightBlue: '#64ABFF',
    brightBlue: '#64abff',
    iconBlue: '#469BFF',
    darkBlue: '#5D7B9D',
    hardBlue: '#207EED', // $cyan500
    fadeBlue: '#5D7B9D',
    lightGray: '#F2F2F2',
    whiteGray: '#B0B0B0',
    royalBlue: '#25539E',
    linkText: '#337ab7',
    text: '#414042',
    black: '#000',
    lightBlack: '#333440', // $backgroundInput
    red: '#B83E26',
    primaryText: '#FFF', // white
    background: '#000',
    tabBarBackground: '#000',
    tabBarTint: '#4D4D4D',
    tabBarTintFocused: '#FFF',
    yellow: '#FFB829',
    white: '#FFF',
    subtleGray: '#969696',
    gray: '#9D9D9D',
    vividCyanBlue: '#186ccf',
    darkPurple: '#121320', // backgroundSite
    darkerPurple: '#252632',
    babyBlue: '#469bff', //cyan400
    darkGray: '#1e1e1e',
    green: '#6AB305',
    babyYellow: '#FCB627',
    opaqueBlue: '#5D7A9C',
    transparent: 'transparent',
    gold: '#FFB829',
    shinnyGreen: '#00C92E',
    blackText: '#0A0A0A',
    lightText: '#AFAFAF', // neutral500
    primary100: '#D5EFFF',
    primary400: '#1AA7FF',
    success400: '#15B300',
    error400: '#FF4D3D',
    cyan400: '#4694F0',
    cyan800: '#0C4991',
    boxes: '#191A27',
    secondary500: '#F0A104',
    textDark: '#888888', // neutral600
    neutral400: '#C9CFCF',
    neutral500: '#A1B0B7',
    neutral600: '#788389',
    neutral700: '#4A5459',
    neutral900: '#141414',
    amber500: '#FEAC01',
    darkback: '#22292D',
    blueBack: '#242538' // backgroundHover
  }
}

export const fonts = StyleSheet.create({
  base: {
    fontSize: 14,
    fontFamily: 'Barlow',
    color: 'white',
    backgroundColor: 'transparent'
  },
  headerBanner: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    lineHeight: 24,
    textAlign: 'left',
    color: '#FFFFFF'
  },
  banner: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 20,
    lineHeight: 24,
    textAlign: 'left',
    color: '#FFFFFF'
  },
  subtitle: {
    fontFamily: 'Barlow',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 17,
    color: '#AFAFAF',
    paddingTop: 2,
    textAlign: 'left'
  },
  normal: {
    fontSize: 14,
    color: 'white',
    backgroundColor: 'transparent',
    textAlign: 'center'
  },
  small: {
    fontSize: 12
  },
  medium: {
    fontSize: 18
  },
  semiBold: {
    fontFamily: 'Barlow',
    fontWeight: '600'
  },
  bold: {
    fontFamily: 'Barlow',
    fontWeight: '700'
  },
  extraBold: {
    fontFamily: 'Barlow',
    fontWeight: '800'
  },
  black: {
    fontFamily: 'Barlow',
    fontWeight: '900'
  },
  center: { textAlign: 'center' },
  fullWidth: { width: '100%' },
  h3: {
    fontFamily: 'Barlow',
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: 4.2,
    color: 'white',
    opacity: 0.5,
    backgroundColor: 'transparent',
    textAlign: 'left',
    textTransform: 'uppercase'
  }
})

export const tabBarActiveBackgroundColor = brand.color.brightBlue

export const tabBarDefaultHeight = 51
export const iPhoneXTabBarOffset = 25

export const tabBarStyles = StyleSheet.create({
  style: {
    backgroundColor: brand.color.tabBarBackground
  },
  icon: {
    tintColor: brand.color.tabBarTint
  },
  newProjectIcon: {
    height: 38,
    width: 38
  },
  iconFocused: {
    tintColor: brand.color.tabBarTintFocused,
    shadowOpacity: 1,
    shadowOffset: { width: -1, height: 0 },
    shadowRadius: 3
  },
  label: {
    color: brand.color.text,
    fontSize: 10,
    top: -3
  },
  labelFocused: {
    color: 'white',
    textShadowOffset: { width: -1, height: 0 },
    textShadowColor: brand.color.fadeBlue,
    textShadowRadius: 3
  }
})
