/* eslint-disable react/destructuring-assignment, no-unused-vars */
import { Honeybadger } from '@honeybadger-io/react'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useErrorDialog } from '../../contexts/Dialog.context'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true
    })
    Honeybadger.notify(error, error.name, {
      ...errorInfo
    })
    this.props.onError()
  }

  render() {
    if (this.state.hasError) {
      return <></>
    }

    return this.props.children
  }
}

export const ErrorTrackingBoundaryBare = ({ isLoggedIn, children }) => {
  const showErrorDialog = useErrorDialog()

  const onError = useCallback(() => {
    showErrorDialog(onClose => ({
      xClick: onClose,
      onClose: () => onClose(),
      primaryText: 'Oops, something went wrong.',
      tryAgainButtonText: 'Reload Page',
      primaryAction: () => {
        onClose()
        window.location.href = '/'
      },
      variant: 'warn'
    }))
  }, [showErrorDialog])

  return <ErrorBoundary onError={onError}>{children}</ErrorBoundary>
}

const mapStateToProps = ({ auth }) => ({
  isLoggedIn: auth.loggedIn
})

export const ErrorTrackingBoundary = connect(mapStateToProps)(ErrorTrackingBoundaryBare)
