import responsiveImage from './responsiveImage'

export const BustDesk = responsiveImage('Silhouette of person with sitting at a desk.', [
  require('../../images/icons/bust_desk.png'),
  require('../../images/icons/bust_desk@2x.png'),
  require('../../images/icons/bust_desk@3x.png')
])

export const BustHardHat = responsiveImage('Silhouette of person wearing a hard hat.', [
  require('../../images/icons/bust_hardhat.png'),
  require('../../images/icons/bust_hardhat@2x.png'),
  require('../../images/icons/bust_hardhat@3x.png')
])

export const BustBun = responsiveImage('Silhouette of person with hair in bun.', [
  require('../../images/icons/bust_bun.png'),
  require('../../images/icons/bust_bun@2x.png'),
  require('../../images/icons/bust_bun@3x.png')
])

export const BustGroup = responsiveImage('Silhouette a group of people.', [
  require('../../images/icons/bust_group.png'),
  require('../../images/icons/bust_group@2x.png'),
  require('../../images/icons/bust_group@3x.png')
])

export const SocialFacebook = responsiveImage('Facebook', [
  require('../../images/icons/social/facebook.png'),
  require('../../images/icons/social/facebook@2x.png'),
  require('../../images/icons/social/facebook@3x.png')
])

export const SocialYelp = responsiveImage('Yelp', [
  require('../../images/icons/social/yelp.png'),
  require('../../images/icons/social/yelp@2x.png'),
  require('../../images/icons/social/yelp@3x.png')
])

export const SocialInstagram = responsiveImage('Instagram', [
  require('../../images/icons/social/instagram.png'),
  require('../../images/icons/social/instagram@2x.png'),
  require('../../images/icons/social/instagram@3x.png')
])

export const HouseTop = responsiveImage('Report icon pointing towards a house top.', [
  require('../../images/report_callout.png'),
  require('../../images/report_callout@2x.png'),
  require('../../images/report_callout@3x.png')
])

export const FindPropertyAddress = responsiveImage('Search icon with sample address.', [
  require('../../images/logos/card1_logo.png'),
  require('../../images/logos/card1_logo@2x.png'),
  require('../../images/logos/card1_logo@3x.png')
])

export const CodeReport = responsiveImage('Icon showing that a code report details IRC, IBC, and IECC codes.', [
  require('../../images/logos/card2_logo.png'),
  require('../../images/logos/card2_logo@2x.png'),
  require('../../images/logos/card2_logo@3x.png')
])

export const SharingOptions = responsiveImage('Icon detailing you can share by email and print, among other ways.', [
  require('../../images/logos/card3_logo.png'),
  require('../../images/logos/card3_logo@2x.png'),
  require('../../images/logos/card3_logo@3x.png')
])

export const NRCA = responsiveImage('National Roofing Contractors Association Member Logo', [
  require('../../images/logos/professional_nrca.png'),
  require('../../images/logos/professional_nrca@2x.png'),
  require('../../images/logos/professional_nrca@3x.png')
])

export const CRA = responsiveImage('Colorado Roofing Association Logo', [
  require('../../images/logos/professional_cra.png'),
  require('../../images/logos/professional_cra@2x.png'),
  require('../../images/logos/professional_cra@3x.png')
])

export const CertainTeed = responsiveImage('CertainTeed Logo', [
  require('../../images/logos/professional_certainteed.png'),
  require('../../images/logos/professional_certainteed@2x.png'),
  require('../../images/logos/professional_certainteed@3x.png')
])
