export const ReportTypesNames = {
  CodeDetail: 'CODE DETAIL',
  ManufacturerRequirements: 'MANUFACTURER REQUIREMENTS',
  BuildingDesignCriteria: 'BUILDING DESIGN CRITERIA',
  HailStormHistory: 'HAIL STORM HISTORY',
  PermitFeeCalculator: 'PERMIT FEE CALCULATOR',
  WasteCalculator: 'WASTE CALCULATOR',
  VentilationCalculator: 'VENTILATION CALCULATOR',
  IWSCalculator: 'ICE & WATER SHIELD CALCULATOR',
  WindStormHistory: 'WIND STORM HISTORY'
}

export const AvailableReportsMap = {
  code_detail: 'CodeDetail',
  manufacturer_report: 'ManufacturerRequirements',
  design_criteria: 'BuildingDesignCriteria',
  hail_detail: 'HailStormHistory',
  permit: 'PermitFeeCalculator'
}

export const REPORT_REQUEST_LIMIT = 10
export const DASHBOARD_REPORT_REQUEST_LIMIT = 15
