import { Box } from '@oneclickdata/components'
import React, { useContext } from 'react'
import { createPortal } from 'react-dom'

export const StickyFooterContext = React.createContext()

export function StickyFooterProvider({ children }) {
  const alertBoxRef = React.useRef(null)

  return <StickyFooterContext.Provider value={{ ref: alertBoxRef }}>{children}</StickyFooterContext.Provider>
}

export function useStickyFooterContext() {
  const context = useContext(StickyFooterContext)
  if (!context) {
    throw new Error('useStickyFooter must be used within a StickyFooterProvider')
  }

  return context
}

export function StickyFooterOutput(props) {
  const alertBox = useStickyFooterContext()

  return <Box ref={alertBox.ref} position="sticky" {...props} />
}

export function StickyFooterPortal({ children }) {
  const stickyFooter = useStickyFooterContext()
  if (!stickyFooter?.ref?.current) {
    return null
  }

  return createPortal(children, stickyFooter.ref.current)
}
