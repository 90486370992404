import { Text, VStack } from '@oneclickdata/components'
import React from 'react'

const NotFound = () => (
  <VStack alignItems="center" justifyContent="center" flex={1}>
    <Text size="xl" semibold>
      404 Not Found
    </Text>
  </VStack>
)

export default NotFound
