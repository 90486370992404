import { API, Auth } from 'aws-amplify'

window.api = API

const BASE_PATH = '/codeReports/profile'

const manager = {
  load: async () => {
    const apiResponse = await API.get('occapi', `/v2/profile/?app=web`)

    return apiResponse
  },

  save: async (profile) => {
    const apiResponse = await API.post('occapi', BASE_PATH, {
      body: profile
    })

    return apiResponse
  },

  updateProfileEmail: async (newEmail) => {
    await Auth.currentCredentials()

    return API.put('occapi', `${BASE_PATH}/email`, { body: { email: newEmail } })
  },

  updateCardInfo: async (cardDetails) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/update_card_info`, {
      body: { cardDetails }
    })

    return apiResponse
  },

  updateDefaultCard: async ({ source }) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/update_default_card`, {
      body: { source }
    })

    return apiResponse
  },

  addPaymentInfo: async ({ source }) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/payment_info`, {
      body: { source }
    })

    return apiResponse
  },

  retryPayment: async () => {
    await Auth.currentCredentials()
    try {
      const apiResponse = await API.post('occapi', `${BASE_PATH}/retry_payment`, {
        body: null
      })

      return apiResponse
    } catch (e) {
      return { success: false, message: e.response.data.message }
    }
  },

  getPaymentInfo: async () => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/payment_info`)

    return apiResponse
  },

  removePaymentInfo: async ({ source }) => {
    await Auth.currentCredentials()
    const apiResponse = await API.del('occapi', `${BASE_PATH}/payment_info`, {
      body: { source }
    })

    return apiResponse
  },

  authorizePayment: async ({ price, reportType }) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/authorize`, {
      body: {
        price,
        reportType
      }
    })

    return apiResponse
  },

  getAllThirdPartyApiKeys: async () => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `/profile/keys`)

    return apiResponse
  },

  finalizeOauthIntegrationConnection: async (platform, body) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/keys/${platform}`, { body })
    // eslint-disable-next-line no-console
    console.log({ apiResponse })

    return apiResponse
  },

  getThirdPartyApiKey: async (platform) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/keys/${platform}`)

    return apiResponse
  },

  deleteThirdPartyApiKey: async (platform) => {
    await Auth.currentCredentials()
    const apiResponse = await API.del('occapi', `${BASE_PATH}/keys/${platform}`)

    return apiResponse
  },

  getBillingHistory: async () => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/billingHistory`)

    return apiResponse
  },

  getTransactionList: async () => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/billingHistory/transaction-list`)

    return apiResponse
  }
}

export default manager
