import React, { useEffect } from 'react'
import { brand } from '../../../utils/CommonStyles'
import useResponsive from '../../../utils/useRepsonsive'
import Buttons from '../../Buttons'
import FormButton from '../../FormButton'
import Modal from '../../Modal'

const styles = {
  modalBackground: {
    backgroundColor: 'rgba(22, 22, 23, 0.8)',
    backgroundImage: 'none'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center'
  },
  title: {
    fontWeight: '800',
    fontSize: 24,
    color: '#fff',
    textAlign: 'center',
    marginBottom: 35
  },
  message: {
    fontFamily: 'Barlow',
    fontWeight: '400',
    fontSize: 22,
    color: '#fff',
    textAlign: 'center',
    maxWidth: 420,
    marginBottom: 25,
    lineHeight: '38px'
  },
  iconContainer: {
    marginBottom: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconContainerTypes: {
    success: {
      width: 64,
      height: 64,
      borderRadius: 64 / 2,
      backgroundColor: brand.color.darkGreen
    }
  },
  icon: {
    alert: {
      width: 65,
      height: 64
    },
    success: {
      width: 32,
      height: 32
    }
  },
  btnsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  disclaimer: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: 12,
    lineHeight: '14px',
    color: 'rgb(150, 150, 150)',
    marginTop: 10
  },
  dangerBackground: {
    background: brand.color.red
  }
}

const YELLOW_EXCLAMATION_MARK_IMG = require('../../../images/icons/icon_yellow_exclamation@3x.png')
const WHITE_CHECKMARK_IMG = require('../../../images/icons/white_checkmark@3x.png')
const X_CLOSE_IMG = require('../../../images/icons/x@3x.png')

const ENTER_KEYCODE = 13
const ESCAPE_KEYCODE = 27

const AlertOverlay = ({
  type = 'alert',
  primaryButtonType = 'standard',
  secondaryButtonType = 'standard',
  title,
  message,
  primaryButtonTitle = 'Yes',
  secondaryButtonTitle = 'No',
  onPrimaryPress,
  onSecondaryPress,
  dismiss,
  disclaimer
}) => {
  useEffect(() => {
    document.addEventListener('keydown', handleKeydown)

    return () => {
      document.removeEventListener('keydown', handleKeydown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleKeydown = (e) => {
    if (e.keyCode === ESCAPE_KEYCODE) dismiss()
    else if (e.keyCode === ENTER_KEYCODE && onPrimaryPress) onPrimaryPress()
  }

  const responsive = useResponsive({
    lg: {
      modalBackground: {
        border: `1px solid ${brand.color.yellow}`,
        borderRadius: 24
      }
    }
  })

  const icons = {
    alert: YELLOW_EXCLAMATION_MARK_IMG,
    success: WHITE_CHECKMARK_IMG
  }

  return (
    <Modal
      dismiss={dismiss}
      backgroundStyle={{
        ...styles.modalBackground,
        ...responsive.modalBackground
      }}
      customDismissIcon={<Buttons.WithIcon style={{ width: 16, height: 16 }} image={X_CLOSE_IMG} />}>
      <div style={styles.contentContainer}>
        <div
          style={{
            ...styles.iconContainer,
            ...styles.iconContainerTypes[type]
          }}>
          <img src={icons[type]} alt="exclamation" style={styles.icon[type]} />
        </div>
        <div style={styles.title}>{title}</div>
        <div style={styles.message}>{message}</div>
        <div
          style={{
            ...styles.btnsContainer
          }}>
          {onPrimaryPress && (
            <FormButton
              tabIndex="0"
              onClick={onPrimaryPress}
              title={primaryButtonTitle}
              style={{
                ...{ marginRight: 20 },
                ...(primaryButtonType === 'danger' && styles.dangerBackground)
              }}
            />
          )}
          {onSecondaryPress && <FormButton onClick={onSecondaryPress} title={secondaryButtonTitle} style={secondaryButtonType === 'danger' && styles.dangerBackground} />}
        </div>
        {disclaimer && <div style={styles.disclaimer}>{disclaimer}</div>}
      </div>
    </Modal>
  )
}

export default AlertOverlay
