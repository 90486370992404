import React, { useEffect, useState } from 'react'
import './index.css'

const EnvTag = ({ env = 'prod' }) => {
  const [clickCount, setClickCount] = useState(0)

  useEffect(() => {
    if (clickCount > 0) {
      const timer = setTimeout(() => setClickCount(0), 500)

      return () => clearTimeout(timer)
    }

    return undefined
  }, [clickCount])

  const handleTripleClick = (e) => {
    if (e.shiftKey) {
      const storageKey = 'showUserDebug'
      const currentValue = localStorage.getItem(storageKey)

      // If the value exists in localStorage, remove it. Otherwise, set it.
      if (currentValue) {
        localStorage.removeItem(storageKey)
      } else {
        localStorage.setItem(storageKey, 'true')
      }
      window.location.reload()
    }
  }

  const handleClick = (e) => {
    setClickCount((prevCount) => {
      const newCount = prevCount + 1
      if (newCount === 3) {
        handleTripleClick(e)
        return 0 // Reset click count after detecting a triple click
      }
      return newCount
    })
  }

  return env === 'prod' ? null : (
    <div id="react_app-env_tag" onClick={handleClick} role="button">
      {env}
    </div>
  )
}

export default EnvTag
