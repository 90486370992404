import React, { createContext, useCallback, useContext, useState } from 'react'
import ErrorModal from '../components/ErrorModal'
import NewModal from '../components/Modals/TrialNewModal'
import AlertOverlay from '../components/molecules/AlertOverlay'

export const DialogContext = createContext({})

const ERROR_MODAL_TYPE = 'error'
const ALERT_MODAL_TYPE = 'alert'
const NEW_MODAL_TYPE = 'trialEnded'

export const useErrorDialog = () => useContext(DialogContext).error
export const useAlertDialog = () => useContext(DialogContext).alert
export const useNewModal = () => useContext(DialogContext).newModal

const ModalType = ({ modalType, ...props }) => {
  switch (modalType) {
    case ERROR_MODAL_TYPE:
      return <ErrorModal {...props} />
    case ALERT_MODAL_TYPE:
      return <AlertOverlay {...props} />
    case NEW_MODAL_TYPE:
      return <NewModal {...props} />
    default:
      return <AlertOverlay {...props} />
  }
}

export default ({ children }) => {
  const [modalType, setModalType] = useState(null)
  const [visible, setVisible] = useState(false)
  const [modalOptions, setModalOptions] = useState({})

  const modalFn = useCallback((newModalType, getModalOptions) => {
    const options = getModalOptions(() => setVisible(false))
    const currentDismiss = options.dismiss
    const currentXClick = options.xClick

    options.dismiss = (...params) => {
      setVisible(false)
      if (currentDismiss) currentDismiss(...params)
    }

    options.xClick = (...params) => {
      setVisible(false)
      if (currentXClick) {
        currentXClick(...params)
      } else if (currentDismiss) {
        currentDismiss(...params)
      }
    }

    setModalOptions(options)
    setModalType(newModalType)
    setVisible(true)
  }, [])

  const error = useCallback(getOps => modalFn(ERROR_MODAL_TYPE, getOps), [modalFn])

  const alert = useCallback(getOps => modalFn(ALERT_MODAL_TYPE, getOps), [modalFn])

  const newModal = useCallback(getOps => modalFn(NEW_MODAL_TYPE, getOps), [modalFn])

  return (
    <DialogContext.Provider value={{ error, alert, newModal }}>
      {children}
      {visible && <ModalType {...modalOptions} modalType={modalType} />}
    </DialogContext.Provider>
  )
}
