import { useShowModal } from '@oneclickdata/components'
import React, { useEffect } from 'react'
import NewLoading from '../../native/components/Loading'
import Spinner from '../../native/components/Spinner'
import etag from '../../utils/etag'
import Modal from '../Modal'

const styles = {
  standardBackground: {
    backgroundImage: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: 'unset'
  },
  standardFade: {
    backgroundColor: 'rgba(22, 22, 23, 0.8)',
    backgroundImage: 'none'
  },
  standardContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  standardText: { marginBottom: 10, fontWeight: '700' }
}

const Standard = ({ label }) => (
  <Modal customDismissIcon={<div> </div>} modalFadeStyle={styles.standardFade} backgroundStyle={styles.standardBackground}>
    <div style={styles.standardContent}>
      <div style={styles.standardText}>{label}</div>
      <Spinner />
    </div>
  </Modal>
)

const Loading = (props) => {
  const { error, pastDelay, standard, timedOut } = props
  const showModal = useShowModal()

  useEffect(() => {
    const checkETag = async () => {
      const etagIsCurrent = await etag.isCacheCurrent()
      if (etagIsCurrent) {
        const { result } = await showModal({
          title: 'Oops!',
          description: "We've had trouble loading the requested page.\n\nPlease go Back, Try Again, or [contact us](https://www.oneclickcode.com/contact-us) for help.",
          dismissible: false,
          primaryActionText: 'Back',
          secondaryActionText: 'Try Again',
          variant: 'warn'
        })
        if (result === 'primary') {
          window.history.go(-1)
        } else {
          window.location.reload()
        }
      } else {
        window.location.reload()
      }
    }

    if (error) {
      checkETag()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  if (standard) {
    return <Standard {...props} />
  }

  if (timedOut) {
    return <div>Taking a long time...</div>
  }

  if (pastDelay) {
    return <NewLoading />
  }

  return null
}

export default Loading
