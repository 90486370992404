import { MODULES } from '@oneclickdata/occ-components'
import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import API from '../api'
import { isProd } from '../envTest'
import { queryClient } from '../queryClient'
import analytics from '../services/analytics'

const staleTime = isProd ? 5 * 60 * 1000 : 15 * 1000
const cacheTime = 60 * 60 * 1000

export const prefetchQuery = query => queryClient.prefetchQuery(query)

/**
 *
 * @param {number} number
 * @param {import('@tanstack/react-query').UseQueryOptions} [options]
 * @returns
 */
export const createProjectQuery = (number, options = {}) => ({
  cacheTime,
  queryKey: ['project', number?.toString()],
  queryFn: async () => {
    if (number === 'sampleCode') {
      const module = await import('../routes/project/sampleCode')
      return module?.sampleCode
    }
    if (number === 'sampleWeather') {
      const module = await import('../routes/project/sampleWeather')
      return module?.sampleWeather
    }

    if (typeof number === 'undefined') {
      return undefined
    }

    const response = await API.getReport({ reportNumber: number })

    try {
      if (response.reportNumber) {
        const simple_modules = response.simple_modules || []

        const typeFragments = ['quickview']

        if (simple_modules.includes(MODULES.ONECLICK_CODE_DETAIL_V1)) typeFragments.push('code')

        if (simple_modules.includes(MODULES.HAILTRACE_HAIL_V1) || simple_modules.includes(MODULES.HAILTRACE_WIND_V1)) typeFragments.push('weather')

        analytics.logEvent('lastUserAccess', {
          lastAccess: Date.now(),
          type: typeFragments.join('_'),
          reportNumber: response.reportNumber
        })
      }
    } catch (e) {
      //
    }

    return response
  },
  ...options
})

/**
 *
 * @param {number} number
 * @param {import('@tanstack/react-query').UseQueryOptions} [options]
 * @returns
 */
export const useProject = (number, options = {}) => useQuery(createProjectQuery(number, options))

// hydrate profile from redux while we havent fully migrated to react-query
const profileSelector = ({ profile }) => ({ profile })
export const useProfile = () => {
  const { profile } = useSelector(profileSelector)
  return useQuery({
    cacheTime,
    queryKey: ['profile'],
    queryFn: () => API.profile.load(),
    initialData: profile
  })
}

export const useListProjects = (params) => {
  const { ownership, teamId, pageNumber, limit, sortBy, order, term, searchAfterIndex } = params

  const query = useQuery({
    cacheTime,
    queryKey: ['list-projects', params],
    queryFn: () =>
      API.projectsSearchAfter({
        ownership,
        teamId,
        pageNumber,
        limit,
        sortBy,
        order,
        term,
        ...(searchAfterIndex && { searchAfterIndex }) // using search_after for pagination
      }),
    keepPreviousData: true
  })

  return {
    ...query,
    updateCache: (data) => {
      queryClient.setQueryData(['list-projects', params], data)
    }
  }
}

export const prefetchListProjects = (params) => {
  queryClient.prefetchQuery({
    queryKey: ['list-projects', params],
    queryFn: () => API.projectsByOwnership(params)
  })
}

export const useUpdateCachedProject = number => data => queryClient.setQueryData(['project', number.toString()], data)

export const createPlanEstimateQuery = params => ({
  staleTime,
  cacheTime,
  queryKey: ['plan-estimate', params],
  queryFn: () => API.orders.estimate(params)
})

export const usePlanEstimate = params => useQuery(createPlanEstimateQuery(params))

export const currentlyOfferedPlansQuery = {
  staleTime,
  cacheTime,
  queryKey: ['currently-offered-plans'],
  queryFn: async () => {
    const { value: plansPageScaffold } = await API.config.get('planPageDetailsV5')
    const activePlans = await API.products.list(plansPageScaffold.products)
    return {
      plansPageScaffold,
      activePlans
    }
  }
}

export const useCurrentlyOfferedPlans = () => useQuery(currentlyOfferedPlansQuery)
