export const ACTUAL_SF = 'actualsf'
export const HALF_ROLL = 'halfroll'
export const FULL_ROLL = 'fullroll'

export const ROUND_TO = {
  [ACTUAL_SF]: 'Actual SF',
  [HALF_ROLL]: 'Half Roll',
  [FULL_ROLL]: 'Full Roll'
}

export const STUD_SIZE_VALUES = ['2x4', '2x6']
export const EXTERIOR_TYPES_VALUES = ['brick', 'siding', 'stucco']
export const IWS_ROUND_TO_VALUES = [ACTUAL_SF, HALF_ROLL, FULL_ROLL]
export const WASTE_ROUND_TO_VALUES = ['actual', 'nearestbundle']

export const IWS_CALCULATOR_NAME = 'iws_calculation'
export const WASTE_CALCULATOR_NAME = 'waste_calculation'
export const VENTILATION_CALCULATOR_NAME = 'ventilation_calculation'
