const mockAvailability = {
  jurisdiction: 10,
  code_detail: 2,
  weather: 1
}

const usage = { ...mockAvailability }

const initialValues = { ...mockAvailability }

const reset = () =>
  Object.keys(initialValues).forEach((key) => {
    mockAvailability[key] = initialValues[key]
    usage[key] = 0
  })

reset()

export const check = async () => {
  const availability = {}
  Object.keys(mockAvailability).forEach((key) => {
    const allocation = mockAvailability[key]
    const used = usage[key]
    availability[key] = {
      allocation,
      used,
      available: allocation - used
    }
  })
  await new Promise(res => setTimeout(() => res(), Math.floor(Math.random() * 5) * 250))

  return availability
}
