import React from 'react'
import Flashy from './flashy'
import './withIcon.css'

const WithIcon = ({ image, label, labelStyle, style, onClick, customIcon: CustomIcon, containerStyle, className, testID, ...rest }) => {
  const extraProps = testID ? { 'data-testid': testID } : {}
  return (
    <Flashy {...extraProps} enlarge onClick={onClick} style={containerStyle}>
      <span {...rest} className={`oneclick-button-with_icon ${className || ''}`}>
        {CustomIcon && <CustomIcon />}
        {image && (
          <img
            alt=""
            src={image}
            style={{
              marginRight: label && 8,
              transition: 'transform 200ms ease-in',
              ...style
            }}
          />
        )}
        {label && <span style={labelStyle}>{label}</span>}
      </span>
    </Flashy>
  )
}

export default WithIcon
