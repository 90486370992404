import useBreakpointHook from 'use-breakpoint'
import { SM_SIZE, XS_SIZE } from '../utils/Responsiveness'

const MOBILE_BREAKPOINT_NAME = 'mobile'
const TABLET_BREAKPOINT_NAME = 'tablet'
const DESKTOP_BREAKPOINT_NAME = 'desktop'

const BREAKPOINTS = {
  [MOBILE_BREAKPOINT_NAME]: 0,
  [TABLET_BREAKPOINT_NAME]: XS_SIZE,
  [DESKTOP_BREAKPOINT_NAME]: SM_SIZE
}

export const useBreakpoint = () => {
  const { breakpoint } = useBreakpointHook(BREAKPOINTS, 'desktop')

  return {
    isMobile: breakpoint === MOBILE_BREAKPOINT_NAME,
    isTablet: breakpoint === TABLET_BREAKPOINT_NAME,
    isDesktop: breakpoint === DESKTOP_BREAKPOINT_NAME
  }
}
