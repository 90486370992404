import { API } from 'aws-amplify'

const BASE_PATH = '/public/reports/hubspotentry'

const manager = {
  update: async (email, updates) => {
    try {
      const apiResponse = await API.post('occapi', BASE_PATH, {
        body: { email, updates }
      })

      return apiResponse
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Unable to call hubspot update', err)
    }
  }
}

export default manager
