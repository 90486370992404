class DoubleCB {}

// eslint-disable-next-line no-underscore-dangle
let _instance = null

if (!_instance) {
  _instance = new DoubleCB()
}

const holder = _instance

export default holder
