import { Auth } from 'aws-amplify'
import jwt_decode from 'jwt-decode'
import * as idassociations from '../idassociations'

export const appleSignIn = async (onSignIn) => {
  try {
    const data = await window.AppleID.auth.signIn()

    const { id_token } = data.authorization
    const expires_at = Date.now() + 7 * 24 * 60 * 60 * 1000

    const decoded = jwt_decode(id_token)

    const user = {
      appleId: decoded.sub,
      email: data?.user?.email,
      givenName: data.user?.name?.firstName,
      familyName: data.user?.name?.lastName,
      ...(data.user?.name && {
        name: `${data.user?.name?.firstName} ${data.user?.name?.lastName}`
      }),
      source: 'federated'
    }
    const { appleId, email } = user
    let userEmail = email
    if (userEmail) {
      await idassociations.storeAppleIDEmail(appleId, userEmail)
    } else {
      userEmail = await idassociations.lookupEmailbyAppleID(appleId) // from lookup
    }
    await Auth.federatedSignIn('appleid.apple.com', { token: id_token, expires_at }, { ...user, email: userEmail })
    if (onSignIn) {
      onSignIn()
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}
