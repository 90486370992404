import React from 'react'
import Flashy from './flashy'

const LargeDone = ({ onClick }) => (
  <Flashy onClick={onClick}>
    <div
      className="oneclick-dashboard-large-button"
      style={{
        background: 'rgba(100,171,255,0.10)',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 45
      }}>
      DONE
    </div>
  </Flashy>
)

export default LargeDone
