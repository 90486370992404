import React from 'react'

import API from '../../api'
import { useProfile } from '../../queryHooks'

function ProfileUTMManager() {
  const { data } = useProfile()

  const userId = data?.userId
  const loadingProfile = data?.loadingProfile
  const utm_updated_at = data?.utm_updated_at

  const url = new URL(window.location.href)
  const searchParams = new URLSearchParams(url.search)

  const utmParams = {}

  // Store UTM parameters from the new URL
  for (const [key, value] of searchParams.entries()) {
    if (key.startsWith('utm_')) {
      utmParams[key] = value
    }
  }

  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = utmParams

  React.useEffect(() => {
    if (utm_updated_at || loadingProfile) {
      return
    }

    if ([utm_source, utm_medium, utm_campaign, utm_term, utm_content].every(item => typeof item === 'undefined')) {
      return
    }

    API.profile.trackUTM({
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content
    })

  }, [userId, loadingProfile, utm_updated_at, utm_source, utm_medium, utm_campaign, utm_term, utm_content])

  return null
}

export default ProfileUTMManager
