import glamorous from 'glamorous'
import React from 'react'
import Text from '../../native/components/brand/Text'
import Spinner from '../../native/components/Spinner'
import { brand, fonts } from '../../utils/CommonStyles'

const styles = {
  processingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center'
  },
  processingBtn: {
    backgroundColor: brand.color.black,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  hollow: {
    width: '100%',
    fontWeight: '700',
    textTransform: 'uppercase',
    fontSize: 14,
    letterSpacing: 1,
    background: '#000',
    border: '1px solid white'
  },
  hollowWithImage: {
    width: '100%',
    fontWeight: '700',
    textTransform: 'uppercase',
    fontSize: 14,
    letterSpacing: 1,
    border: '1px solid white'
  }
}

const Button = glamorous.button({
  background: 'linear-gradient(rgb(24, 108, 207), rgb(70, 155, 255))',
  borderRadius: 10,
  height: 50,
  fontWeight: '700',
  fontSize: 12,
  color: '#FFF',
  letterSpacing: 1,
  textAlign: 'center',
  textTransform: 'uppercase',
  marginTop: 20,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  ':not(:disabled):active': {
    opacity: 0.4
  },
  '@media only screen and (min-width: 768px) and (min-height: 500px)': {
    width: '100%',
    marginTop: 15
  }
})

const FormButton = ({
  title = '.title',
  processing = false,
  processingTitle,
  processingTitleStyle,
  processingContainerStyle,
  hollow = false,
  hollowWithImage = false,
  style,
  testID,
  ...props
}) => (
  <Button
    data-testid={testID}
    style={{
      ...style,
      ...(processing && styles.processingBtn),
      ...(processing && processingContainerStyle),
      ...(hollow && styles.hollow),
      ...(hollowWithImage && styles.hollowWithImage)
    }}
    {...props}>
    {processing ? <Processing processingTitle={processingTitle} processingTitleStyle={processingTitleStyle} /> : title}
  </Button>
)

const Processing = ({ processingTitle = 'PROCESSING ...', processingTitleStyle }) => (
  <div style={styles.processingContainer}>
    <Spinner />
    <Text
      style={[
        fonts.base,
        fonts.semiBold,
        {
          fontSize: 13,
          letterSpacing: 1.3,
          marginTop: 10
        },
        processingTitleStyle
      ]}>
      {processingTitle}
    </Text>
  </div>
)

export default FormButton
