import React from 'react'
import { matchPixelDescriptor, matchWidthDescriptor } from './matcher'

export default class Image extends React.PureComponent {
  constructor(props) {
    super(props)

    this.widthDescriptorOnly = Object.keys(props.srcSet).every(descriptor => matchWidthDescriptor(descriptor))
  }

  buildSrcSet() {
    const { srcSet } = this.props
    const matcher = this.widthDescriptorOnly ? matchWidthDescriptor : matchPixelDescriptor
    return (
      Object.keys(srcSet)
        .filter(matcher)
        .map(descriptor => `${srcSet[descriptor]} ${descriptor}`)
        .join(',') || undefined
    )
  }

  buildSizes() {
    const { sizes } = this.props
    if (sizes && this.widthDescriptorOnly) {
      return (
        sizes
          .map((size) => {
            if (size.mediaCondition) {
              return `${size.mediaCondition} ${size.size}`
            }

            return `${size.size}`
          })
          .join(',') || undefined
      )
    }

    return undefined
  }

  render() {
    const { alt, className, src, ...props } = this.props

    return <img alt={alt} className={className} src={src} {...props} srcSet={this.buildSrcSet()} sizes={this.buildSizes()} />
  }
}

Image.defaultProps = {
  alt: ''
}
