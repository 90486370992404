import glamorous from 'glamorous'

const SmallRadius = glamorous.button(
  {
    borderRadius: 4,
    height: 40,
    lineHeight: 'normal',
    padding: '0 15px',
    fontWeight: '700',
    fontSize: 12,
    letterSpacing: 1,
    textAlign: 'center',
    textTransform: 'uppercase',
    transition: 'all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94)'
  },
  ({ bare }) => ({
    background: 'linear-gradient(#186ccf, #469bff)',
    color: bare ? '#A2C3EA' : '#FFF',
    ':active': {
      background: bare ? 'none' : '#352020',
      color: bare ? '#352020' : undefined
    }
  })
)

export default SmallRadius
