import React from 'react'
import { Plus } from '.'
import Flashy from './flashy'

const LargePlus = ({ onClick, id }) => (
  <Flashy onClick={onClick} id={id}>
    <div
      className="oneclick-dashboard-large-button"
      style={{
        background: 'rgba(100,171,255,0.10)',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <img src={Plus} alt="plus" />
    </div>
  </Flashy>
)

export default LargePlus
