import { API } from 'aws-amplify'

const BASE_PATH = '/public/reports/config'

const manager = {
  get: async (variable) => {
    const path = `${BASE_PATH}/${variable}`
    try {
      const apiResponse = await API.get('occapi', path)

      return apiResponse
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Unable to call public config variable', err)
    }
  }
}

export default manager
