import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import API from '../api'
const SURVEY_KEY = 'survey'

export const SURVEYS = {
  cancellation_reason: 'cancellation-reason'
}

export function getSurveyQueryKey(surveyId) {
  return [SURVEY_KEY, surveyId]
}

export function useSurveyQuery(surveyId) {
  return useQuery({
    queryKey: getSurveyQueryKey(surveyId),
    queryFn: async () => API.surveys.get(surveyId)
  })
}

export function usePrefetchSurvey() {
  const queryClient = useQueryClient()
  const prefetchSurvey = useCallback(
    async (surveyId) => {
      return queryClient.prefetchQuery({
        queryKey: getSurveyQueryKey(surveyId),
        queryFn: async () => API.surveys.get(surveyId)
      })
    },
    [queryClient]
  )
  return prefetchSurvey
}
