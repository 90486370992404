import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

const { protocol, hostname, pathname } = window.location
const port = window.location.port ?? ''
const FQDN = `${protocol}//${hostname}${port ? `:${port}` : ''}${pathname}`

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MICROSOFT_SIGNIN_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: FQDN
  },
  cache: {
    cacheLocation: 'localStorage',
    temporaryCacheLocation: 'localStorage'
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            // eslint-disable-next-line no-console
            console.error(message)

            return
          case LogLevel.Info:
            // eslint-disable-next-line no-console
            console.info(message)

            return
          case LogLevel.Verbose:
            // eslint-disable-next-line no-console
            console.debug(message)

            return
          case LogLevel.Warning:
            // eslint-disable-next-line no-console
            console.warn(message)

            return
          default:
        }
      }
    }
  },
  windowHashTimeout: 9000, // Applies just to popup calls - In milliseconds
  iframeHashTimeout: 9000, // Applies just to silent calls - In milliseconds
  loadFrameTimeout: 9000 // Applies to both silent and popup calls - In milliseconds
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: ['User.Read', 'openid', 'profile', 'email']
}

export const logoutRequest = (homeAccountId) => {
  return { account: PublicClientApplication.getAccountByHomeId(homeAccountId)}
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
