// eslint-disable-next-line no-underscore-dangle
let _env = 'prod'

if (window.location.hostname.includes('localhost') || window.location.hostname.includes('192.168') || window.location.hostname.includes('dev')) {
  _env = 'dev'
} else if (window.location.hostname.includes('beta')) {
  _env = 'beta'
}

const isProd = _env === 'prod'

if (!isProd) {
  // eslint-disable-next-line no-console
  console.log(
    `=====================================
running as ${_env}
=====================================
`
  )}

const env = _env

export { env, isProd }
