import { API, Auth } from 'aws-amplify'

window.api = API
const BASE_PATH = '/v2/surveys'

const manager = {
  get: async (id) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/${id}`)

    return apiResponse
  },

  post: async (id, values) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/${id}`, { body: { values } })

    return apiResponse
  }
}

export default manager
