/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import withCookies from '../components/HOC/withCookies'
import withLayout from '../components/HOC/withLayout'
import ProfileUTMManager from '../components/ProfileUTMManager'
import AuthenticatedRoute from '../components/atoms/AuthenticatedRoute'
import NonAuthenticatedRoute from '../components/atoms/NonAuthenticatedRoute'
import { ROUTES_NAMES } from '../routes/constants'
import NotFound from '../routes/notFound'
import AuthenticatedRouter from './AuthenticatedRouter'
import { quickLoadable } from './helpers.routers'

const Login = quickLoadable(() => import(/* webpackChunkName: "login" */ '../containers/login'))

const Enterpriselogin = quickLoadable(() => import(/* webpackChunkName: "enterpriselogin" */ '../containers/enterpriselogin'))

// reusing the same enterpriselogin container w/ or w/o team
const SignUpWithSSOTeam = quickLoadable(() => import(/* webpackChunkName: "enterpriselogin" */ '../containers/enterpriselogin'))
const SignInWithIdP = quickLoadable(() => import(/* webpackChunkName: "idpauth" */ '../containers/idpauth'))

const Splash = quickLoadable(() => import(/* webpackChunkName: "signup" */ '../containers/signup'))

const WelcomeBack = quickLoadable(() => import(/* webpackChunkName: "welcomeBack" */ '../containers/welcomeBack'))

const IntroduceYourself = quickLoadable(() => import(/* webpackChunkName: "IntroduceYourself" */ '../containers/introduceYourself'))

const AcceptTos = quickLoadable(() => import(/* webpackChunkName: "IntroduceYourself" */ '../containers/acceptTos'))

const SignUpWithTeam = quickLoadable(() => import('../containers/singupWithTeam'))

// OLD UNAUTH

const ConfirmSignUp = quickLoadable(() => import(/* webpackChunkName: "confirmSignup" */ '../containers/confirmSignUp'))

const ForgotPassword = quickLoadable(() => import(/* webpackChunkName: "forgotPassword" */ '../containers/forgotPassword'))

const ForgotPasswordSubmit = quickLoadable(() => import(/* webpackChunkName: "forgotPasswordSubmit" */ '../containers/forgotPasswordSubmit'))

const ReportRequest = quickLoadable(() => import(/* webpackChunkName: "ReportRequest" */ '../routes/reportRequest'))

const ProjectFor = quickLoadable(() => import(/* webpackChunkName: "report" */ '../routes/projectFor'))

const Project = quickLoadable(() => import(/* webpackChunkName: "report" */ '../routes/project'))

function CommandBarNavigator() {
  const navigate = useNavigate()

  useEffect(() => {
    const routerFunc = (newUrl) => {
      try {
        // Try to create a new URL object, which will succeed for full URLs (with domain)
        const url = new URL(newUrl, window.location.origin)

        const isSameDomain = url.origin === window.location.origin

        if (isSameDomain) {
          navigate(url.pathname + url.search + url.hash)
        } else {
          window.open(url.href, '_blank')
        }
      } catch (error) {
        navigate(newUrl)
      }
    }

    if (window.CommandBar) {
      window.CommandBar.addRouter(routerFunc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

const Routers = () => {
  const location = useLocation()

  return (
    <>
      <CommandBarNavigator />
      <ProfileUTMManager />
      <Routes>
        <Route path={ROUTES_NAMES.splash.route} element={<NonAuthenticatedRoute component={Splash} />} />
        {/* redirect from any old /main to a non /main route */}
        <Route path={ROUTES_NAMES.legacy.route} element={<Navigate to={location.pathname.replace('/main', '/')} replace />} />
        {/* routes used for testing */}
        <Route path={ROUTES_NAMES.report.route} element={<ReportRequest />} />
        {/* routes only accessible by signed out users */}
        <Route path={ROUTES_NAMES.welcomeBack.route} element={<NonAuthenticatedRoute component={withCookies(WelcomeBack)} />} />
        <Route path={ROUTES_NAMES.enterpriselogin.route} element={<NonAuthenticatedRoute component={withCookies(Enterpriselogin)} />} />
        <Route path={ROUTES_NAMES.login.route} element={<NonAuthenticatedRoute component={withCookies(Login)} />} />
        <Route path={ROUTES_NAMES.confirmSignup.route} element={<NonAuthenticatedRoute component={withCookies(ConfirmSignUp)} />} />
        <Route path={ROUTES_NAMES.SignupWithTeam.route} element={<NonAuthenticatedRoute component={withCookies(SignUpWithTeam)} />} />
        <Route path={ROUTES_NAMES.SignupWithSSOTeam.route} element={<NonAuthenticatedRoute component={withCookies(SignUpWithSSOTeam)} />} />
        <Route path={ROUTES_NAMES.SignInWithIdP.route} element={<NonAuthenticatedRoute component={withCookies(SignInWithIdP)} />} />{' '}
        {/* TODO: new component: SignInWithIdP verify if it works with reactv18.2 */}
        <Route path={ROUTES_NAMES.forgotPassword.route} element={<NonAuthenticatedRoute component={withCookies(ForgotPassword)} />} />
        <Route path={ROUTES_NAMES.forgotPasswordSubmit.route} element={<NonAuthenticatedRoute component={withCookies(ForgotPasswordSubmit)} />} />
        {withLayout(<Route path={`${ROUTES_NAMES.report.route}/for`} element={<ProjectFor />} />)}
        {withLayout(<Route path={`${ROUTES_NAMES.report.route}/:number`} element={<Project />} />)}
        <Route path={ROUTES_NAMES.introduceYourself.route} element={<AuthenticatedRoute component={IntroduceYourself} />} />
        <Route path={ROUTES_NAMES.acceptTos.route} element={<AuthenticatedRoute component={AcceptTos} />} />

        {AuthenticatedRouter}
        <Route path="*" Component={NotFound} />
      </Routes>
    </>

  )
}

export default Routers
