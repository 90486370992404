import 'firebase/analytics'
import * as firebase from 'firebase/app'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

let firebaseApp = null
let firebaseAnalytics = null

if (!firebaseApp) {
  firebaseApp = firebase.initializeApp(firebaseConfig)
  firebaseAnalytics = firebase.analytics(firebaseApp)
}

export { firebaseAnalytics, firebaseApp }
