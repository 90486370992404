import {
  APIOutlinedIcon,
  Box,
  BulbOutlinedIcon,
  Button,
  ButtonText,
  CreditCardOutlinedIcon,
  HStack,
  Icon,
  MenuIcon,
  Pressable,
  Text,
  UserIcon,
  useShowModal
} from '@oneclickdata/components'
import { Logo } from '@oneclickdata/occ-components'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import API from '../../api'
import { ROUTES_NAMES } from '../../constants'
import { useDashboardContext } from '../../contexts/Dashboard.context'
import { useClickOutside } from '../../customHooks'
import { useLimits } from '../../customHooks/useLimits'
import { useTeam } from '../../customHooks/useTeam'
import { unsetLoginUI } from '../../modules/auth'
import { setSignUpActiveSlide } from '../../modules/signup'
import { currentlyOfferedPlansQuery, prefetchQuery } from '../../queryHooks'
import { SURVEYS, usePrefetchSurvey } from '../../queryHooks/surveys'
import Menu, { MenuLink } from '../Menu'
import UserDebug from '../UserDebug'
import PurchaseProPlan from '../organisms/PurchaseProPlan'

const {
  myTeam: { route: teamRoute },
  dashboard: { route: dashboardRoute },
  calculators: { route: toolsRoute },
  plans: { route: plansRoute },
  integrations: { route: integrationsRoute },
  profileFull: { route: profileFullRoute },
  subscription: { route: subscriptionFullRoute }
} = ROUTES_NAMES

const NotificationIcon = () => (
  <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="4" cy="4" r="4" fill="#FCB627" />
  </svg>
)

const HeaderLink = ({ testID, text, to, onClick, asButton, ctaIcon }) => {
  const location = useLocation()

  return (
    <Link
      data-testid={testID}
      to={to}
      onClick={(e) => {
        if (asButton) {
          e.preventDefault()
        }
        if (onClick) {
          onClick()
        }
      }}>
      <HStack py="$7" borderBottomWidth={3} borderBottomColor={location.pathname === to ? '$cyan400' : 'transparent'} flexDirection="row">
        <Text size="sm" medium>
          {text}
        </Text>
        {ctaIcon && <NotificationIcon />}
      </HStack>
    </Link>
  )
}

const Header = ({ profile, flag_team_notification_seen, onLogout }) => {
  const [isMenuOpen, setMenuOpen] = React.useState(false)
  const [showProPlanModal, setShowProPlanModal] = React.useState(false)
  const [isTeamNotificationSeen, setTeamNotificationSeen] = React.useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const menuRef = React.useRef()
  const activeSubscription = profile?.subscriptions?.[0]
  const integrationsAllowed = activeSubscription?.limits?.allow_integration_access
  const teamsAllowed = activeSubscription?.limits?.allow_team_access
  const limits = useLimits()
  const availabileTools = limits?.ui_configuration?.availabile_calculators
  const team = useTeam()
  const isAdmin = team?.members.find(x => x.id === profile?.userId)?.role === 'admin' || team?.members.find(x => x.id === profile?.userId)?.role === 'owner'

  let showUpgradeButton =
    activeSubscription?.type === 'free-plan' || activeSubscription?.type === 'trial-plan' || activeSubscription?.archivedDate || activeSubscription?.is_expired

  if (team && profile?.userId !== team?.admin_id) {
    showUpgradeButton = false
  }

  useClickOutside(menuRef, () => setMenuOpen(false))
  const showModal = useShowModal()

  useEffect(() => {
    const loadModal = async () => {
      await showModal({
        isOpen: showProPlanModal,
        children: <PurchaseProPlan displayType="Integrations" onButtonClick={() => setShowProPlanModal(false)} />,
        onClose: () => setShowProPlanModal(false)
      })
    }
    loadModal()
  }, [showProPlanModal, showModal])

  useEffect(() => {
    setTeamNotificationSeen(!!flag_team_notification_seen)
  }, [flag_team_notification_seen])

  const prefetchSurvey = usePrefetchSurvey()
  const handleToggleMenu = () => {
    setMenuOpen(menuOpen => !menuOpen)
    prefetchSurvey(SURVEYS.cancellation_reason)
  }

  const handleIntegrationsClick = () => {
    if (integrationsAllowed) {
      handleToggleMenu()
    } else {
      setShowProPlanModal(true)
    }
  }

  const handlePlansClick = () => {
    handleToggleMenu()
    navigate(subscriptionFullRoute, {
      state: {
        from: location.pathname
      }
    })
  }

  const handleTeamsClick = async () => {
    navigate(teamRoute, {
      state: {
        from: location.pathname
      }
    })
    if (!flag_team_notification_seen && !teamsAllowed) {
      try {
        await API.profile.save({
          flag_team_notification_seen: true
        })
        setTeamNotificationSeen(true)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
  }

  const { reset } = useDashboardContext()
  const handleProjectsClick = reset

  return (
    <HStack justifyContent="space-between" align="center" width="100%" px="$4">
      <HStack alignItems="center" gap="$10">
        <Link to={dashboardRoute}>
          <Logo variant="solo" width="7" />
        </Link>
        <HStack gap="44px">
          <HeaderLink testID="qa-projects-tab" to={dashboardRoute} text="Projects" onClick={handleProjectsClick} />
          {!!availabileTools?.length && <HeaderLink testID="qa-tools-tab" to={toolsRoute} text="Tools" />}
          <HeaderLink testID="qa-team-tab" to={teamRoute} onClick={handleTeamsClick} asButton text="Team" ctaIcon={!isTeamNotificationSeen && !teamsAllowed} />
        </HStack>
      </HStack>
      <HStack alignItems="center" space="md">
        <UserDebug />
        {showUpgradeButton && (
          <Button flex={1} action="secondary" onHoverIn={() => prefetchQuery(currentlyOfferedPlansQuery)} onPress={() => navigate(plansRoute)}>
            <ButtonText>Upgrade</ButtonText>
          </Button>
        )}
        <Box position="relative" ref={menuRef}>
          <Pressable
            testID="qa-burgermenu-btn-d"
            onPress={handleToggleMenu}
            p="$2"
            sx={{
              ':hover': {
                backgroundColor: '$backgroundHover',
                borderRadius: '$sm'
              }
            }}>
            <Icon as={MenuIcon} color="$cyan500" size="xl" />
          </Pressable>
          {isMenuOpen && (
            <Box
              minW={300}
              backgroundColor="$backgroundBoxes"
              borderColor="$backgroundInput"
              pb="$2"
              borderWidth={1}
              borderRadius="$sm"
              position="absolute"
              right={0}
              top="100%"
              zIndex={99}>
              <Menu plan={activeSubscription} onLogout={onLogout} onClose={handleToggleMenu}>
                <Box mt="$2">
                  <MenuLink testID="qa-managesubscription-menu-d" to={subscriptionFullRoute} text={isAdmin ? 'Manage Subscription' : 'Plan Details'} icon={CreditCardOutlinedIcon} onClick={handlePlansClick} />
                  {integrationsAllowed && <MenuLink testID="qa-integrations-menu-d" to={integrationsRoute} text="Integrations" icon={APIOutlinedIcon} onClick={handleIntegrationsClick} />}
                  <MenuLink testID="qa-profile-menu-d" to={profileFullRoute} text="Profile" icon={UserIcon} onClick={handleToggleMenu} />
                  <MenuLink
                    testID="qa-industrynews-menu-d"
                    text="Industry news"
                    icon={BulbOutlinedIcon}
                    onClick={() => {
                      handleToggleMenu()
                      window.open('https://www.oneclickcode.com/blog', '_blank')
                    }}
                  />
                </Box>
              </Menu>
            </Box>
          )}
        </Box>
      </HStack>
    </HStack>
  )
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      unsetLoginUI,
      setSignUpActiveSlide
    },
    dispatch
  )

const mapStateToProps = ({ profile }) => ({
  profile,
  flag_team_notification_seen: profile.flag_team_notification_seen
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
