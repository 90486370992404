import React, { createContext, useContext, useMemo, useState } from 'react'

const DashboardContext = createContext()

export const useDashboardContext = () => useContext(DashboardContext)

const initialLimit = 5
const initialPage = 1

export const DASHBOARD_INITIAL_VALUES = {
  searchAddress: '',
  sortBy: 'createdAt',
  sortType: 'desc',
  page: initialPage,
  tableCache: null,
  filterBy: 'userId',
  limit: initialLimit,
  from: initialLimit * (initialPage - 1) + 1,
  to: initialLimit * initialPage
}

export const DashboardProvider = ({ children }) => {
  const [searchAddress, setSearchAddress] = useState(DASHBOARD_INITIAL_VALUES.searchAddress)
  const [sortBy, setSortBy] = useState(DASHBOARD_INITIAL_VALUES.sortBy)
  const [sortType, setSortType] = useState(DASHBOARD_INITIAL_VALUES.sortType)
  const [page, setPage] = useState(DASHBOARD_INITIAL_VALUES.page)
  const [tableCache, setTableCache] = useState(DASHBOARD_INITIAL_VALUES.tableCache)
  const [filterBy, setFilterBy] = useState(DASHBOARD_INITIAL_VALUES.filterBy)
  const [limit, setLimit] = useState(DASHBOARD_INITIAL_VALUES.limit)
  const [from, setFrom] = useState(DASHBOARD_INITIAL_VALUES.from)
  const [to, setTo] = useState(DASHBOARD_INITIAL_VALUES.to)
  const [viewExisting, setViewExisting] = useState(false)

  const reset = () => {
    setSearchAddress(DASHBOARD_INITIAL_VALUES.searchAddress)
    setSortBy(DASHBOARD_INITIAL_VALUES.sortBy)
    setSortType(DASHBOARD_INITIAL_VALUES.sortType)
    setPage(DASHBOARD_INITIAL_VALUES.page)
    setTableCache(DASHBOARD_INITIAL_VALUES.tableCache)
    setFilterBy(DASHBOARD_INITIAL_VALUES.filterBy)
    setLimit(DASHBOARD_INITIAL_VALUES.limit)
    setFrom(DASHBOARD_INITIAL_VALUES.from)
    setTo(DASHBOARD_INITIAL_VALUES.to)
  }

  const state = useMemo(
    () => ({
      searchAddress,
      setSearchAddress,
      sortBy,
      setSortBy,
      sortType,
      setSortType,
      page,
      setPage,
      tableCache,
      setTableCache,
      limit,
      setLimit,
      filterBy,
      setFilterBy,
      from,
      setFrom,
      to,
      setTo,
      reset,
      viewExisting,
      setViewExisting
    }),
    [searchAddress, sortBy, sortType, page, tableCache, filterBy, limit, from, to, viewExisting]
  )

  return <DashboardContext.Provider value={state}>{children}</DashboardContext.Provider>
}
