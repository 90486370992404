import FocusTrap from 'focus-trap-react'
import React from 'react'
import { TestIDs } from '../../utils/testing'
import Buttons from '../Buttons'
import ModalPortal from '../ModalPortal'
import { Modal as Images } from '../ResponsiveImages'
import './modal.css'

const Modal = ({
  dismiss,
  backgroundClassName,
  children,
  disableDismiss,
  backgroundStyle,
  customDismissIcon,
  withoutDismissIcon,
  dismissIconContainerStyle,
  modalFadeStyle,
  testID
}) => {
  const stopClick = e => e.stopPropagation()

  return (
    <ModalPortal maintainTop>
      <FocusTrap active={false}>
        <div
          data-testid={testID}
          className="oneclick-modal-fade"
          onClick={dismiss}
          style={{
            ...modalFadeStyle,
            ...(disableDismiss && { pointerEvents: 'none' })
          }}>
          <div className={backgroundClassName ? `${backgroundClassName} oneclick-modal-background` : 'oneclick-modal-background'} style={backgroundStyle} onClick={stopClick}>
            {!withoutDismissIcon && (
              <div className="oneclick-modal-close" style={dismissIconContainerStyle}>
                <Buttons.Flashy onClick={dismiss} tabIndex="0" data-testid={TestIDs.modalCloseButton}>
                  {customDismissIcon || <Images.Close style={{ height: 17, width: 17 }} />}
                </Buttons.Flashy>
              </div>
            )}
            {children}
          </div>
        </div>
      </FocusTrap>
    </ModalPortal>
  )
}

export default Modal
