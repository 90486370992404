import React from 'react'
import { StyleSheet, View } from 'react-native'
import Spinner from '../Spinner'

const Loading = () => (
  <View style={styles.container}>
    <Spinner />
  </View>
)

export default Loading

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
