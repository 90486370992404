import { API, Auth } from 'aws-amplify'

const BASE_PATH = '/feedback'

const manager = {
  createTicket: async (details) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', BASE_PATH, {
      body: { details }
    })

    return apiResponse
  }
}

export default manager
