import { Icon } from '@oneclickdata/occ-components'
import React from 'react'
import responsiveImage from './responsiveImage'

export const Burger = responsiveImage('View Menu.', [
  require('../../images/icons/burger.png'),
  require('../../images/icons/burger@2x.png'),
  require('../../images/icons/burger@3x.png')
])

export const Profile = responsiveImage('View Profile.', [
  require('../../images/icons/user1_white.png'),
  require('../../images/icons/user1_white@2x.png'),
  require('../../images/icons/user1_white@3x.png')
])

export const ChangePassword = responsiveImage('Change Password.', [
  require('../../images/icons/lock_white.png'),
  require('../../images/icons/lock_white@2x.png'),
  require('../../images/icons/lock_white@3x.png')
])

export const Help = responsiveImage('Help.', [
  require('../../images/icons/menu_help.png'),
  require('../../images/icons/menu_help@2x.png'),
  require('../../images/icons/menu_help@3x.png')
])

export const Insights = responsiveImage('Insights.', [
  require('../../images/icons/icon_news_white.png'),
  require('../../images/icons/icon_news_white@2x.png'),
  require('../../images/icons/icon_news_white@3x.png')
])
export const Support = responsiveImage('Support.', [
  require('../../images/icons/icon__website_copy.png'),
  require('../../images/icons/icon__website_copy@2x.png'),
  require('../../images/icons/icon__website_copy@3x.png')
])

export const TermsOfService = responsiveImage('Terms Of Service.', [
  require('../../images/icons/report_white.png'),
  require('../../images/icons/report_white@2x.png'),
  require('../../images/icons/report_white@3x.png')
])

export const PrivacyPolicy = responsiveImage('Privacy Policy.', [
  require('../../images/icons/shield_white.png'),
  require('../../images/icons/shield_white@2x.png'),
  require('../../images/icons/shield_white@3x.png')
])

export const TermsOfSale = responsiveImage('Terms Of Sale.', [
  require('../../images/icons/icon_terms_of_sale.png'),
  require('../../images/icons/icon_terms_of_sale@2x.png'),
  require('../../images/icons/icon_terms_of_sale@3x.png')
])

export const Feedback = responsiveImage('Provide Feedback.', [
  require('../../images/icons/feedback_white.png'),
  require('../../images/icons/feedback_white@2x.png'),
  require('../../images/icons/feedback_white@3x.png')
])

export const Signout = responsiveImage('Signout.', [
  require('../../images/icons/signout_white.png'),
  require('../../images/icons/signout_white@2x.png'),
  require('../../images/icons/signout_white@3x.png')
])

export const ApiKey = responsiveImage('Integrations.', [
  require('../../images/icons/api_key_white.png'),
  require('../../images/icons/api_key_white@2x.png'),
  require('../../images/icons/api_key_white@3x.png')
])

export const Subscription = responsiveImage('Subscription', [
  require('../../images/icons/icon_subscription_white.png'),
  require('../../images/icons/icon_subscription_white@2x.png'),
  require('../../images/icons/icon_subscription_white@3x.png')
])

export const FAQ = responsiveImage('FAQ', [
  require('../../images/icons/faq/White.png'),
  require('../../images/icons/faq/White@2x.png'),
  require('../../images/icons/faq/White@3x.png')
])

export const Team = responsiveImage('Team', [
  require('../../images/icons/icon_team_white.png'),
  require('../../images/icons/icon_team_white@2x.png'),
  require('../../images/icons/icon_team_white@3x.png')
])

export const BlueProfile = responsiveImage('View Profile', [
  require('../../images/icons/user1_blue.png'),
  require('../../images/icons/user1_blue@2x.png'),
  require('../../images/icons/user1_blue@3x.png')
])

export const BlueWebsite = responsiveImage('View Support', [
  require('../../images/icons/blue_website.png'),
  require('../../images/icons/blue_website@2x.png'),
  require('../../images/icons/blue_website@3x.png')
])

export const BlueReport = responsiveImage('View Terms Of Service.', [
  require('../../images/icons/report_blue.png'),
  require('../../images/icons/report_blue@2x.png'),
  require('../../images/icons/report_blue@3x.png')
])

export const BluePrivacy = responsiveImage('View Privacy Policy.', [
  require('../../images/icons/shield_blue.png'),
  require('../../images/icons/shield_blue@2x.png'),
  require('../../images/icons/shield_blue@3x.png')
])

export const BlueFeedback = responsiveImage('Provide Feedback.', [
  require('../../images/icons/feedback_blue.png'),
  require('../../images/icons/feedback_blue@2x.png'),
  require('../../images/icons/feedback_blue@3x.png')
])

export const BlueChangePassword = responsiveImage('Change Password.', [
  require('../../images/icons/lock_blue.png'),
  require('../../images/icons/lock_blue@2x.png'),
  require('../../images/icons/lock_blue@3x.png')
])

export const BlueSignout = responsiveImage('Signout.', [
  require('../../images/icons/signout_blue.png'),
  require('../../images/icons/signout_blue@2x.png'),
  require('../../images/icons/signout_blue@3x.png')
])

export const BlueTermsOfSale = responsiveImage('Terms Of Sale.', [
  require('../../images/icons/blue_icon_terms_of_sale.png'),
  require('../../images/icons/blue_icon_terms_of_sale@2x.png'),
  require('../../images/icons/blue_icon_terms_of_sale@3x.png')
])

export const BlueApiKey = responsiveImage('Integrations', [
  require('../../images/icons/api_key_blue.png'),
  require('../../images/icons/api_key_blue@2x.png'),
  require('../../images/icons/api_key_blue@3x.png')
])

export const BlueFAQ = responsiveImage('FAQ', [
  require('../../images/icons/faq/Blue.png'),
  require('../../images/icons/faq/Blue@2x.png'),
  require('../../images/icons/faq/Blue@3x.png')
])

export const BlueSubscription = responsiveImage('Subscription', [
  require('../../images/icons/icon_subscription_blue.png'),
  require('../../images/icons/icon_subscription_blue@2x.png'),
  require('../../images/icons/icon_subscription_blue@3x.png')
])

export const BlueTeam = responsiveImage('BlueTeam', [
  require('../../images/icons/icon_team_blue.png'),
  require('../../images/icons/icon_team_blue@2x.png'),
  require('../../images/icons/icon_team_blue@3x.png')
])
export const BlueInsights = responsiveImage('Insights.', [
  require('../../images/icons/icon_news_blue.png'),
  require('../../images/icons/icon_news_blue@2x.png'),
  require('../../images/icons/icon_news_blue@3x.png')
])

export const BlueIWSCalculationIcon = props => <Icon {...props} name="iws" color="primary.500" />

export const BlueVentilationCalculationIcon = props => <Icon {...props} name="vent" color="primary.500" />
export const BlueWasteCalculationIcon = props => <Icon {...props} name="waste" color="primary.500" />

export const IWSCalculationIcon = props => <Icon {...props} name="iws" />

export const VentilationCalculationIcon = props => <Icon {...props} name="vent" />
export const WasteCalculationIcon = props => <Icon {...props} name="waste" />
