import { Dimensions } from 'react-native-web'

export const WIDTH_SIZES = {
  XS: 'xs',
  SM: 'sm',
  LG: 'lg',
  XL: 'xl'
}

export const XS_SIZE = 576
export const SM_SIZE = 1168

export const getBreakpoint = ({ width } = Dimensions.get('window')) => {
  if (width <= XS_SIZE) {
    return 'xs'
  } else if (width <= SM_SIZE) {
    return 'sm'
  } else if (width <= 1168) {
    return 'lg'
  } else {
    return 'xl'
  }
}

export const isXS = () => getBreakpoint() === WIDTH_SIZES.XS
export const isSM = () => getBreakpoint() === WIDTH_SIZES.SM
export const isLG = () => getBreakpoint() === WIDTH_SIZES.LG
export const isXL = () => getBreakpoint() === WIDTH_SIZES.XL

export const biggerOrEqualThan = size => window.innerWidth >= size
export const biggerThan = size => window.innerWidth > size
