const getUserDisplayName = (profile) => {
  //  let displayName = ''
  const { displayName } = profile?.name ? profile.name.split(' ')[0] : profile.email.split('@')[0]
  //  if (profile?.name) displayName = profile.name.split(' ')[0]
  //  else displayName = profile.email.split('@')[0]

  return displayName
}

export default getUserDisplayName
