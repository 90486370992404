import { v4 as uuidv4 } from 'uuid'

export const getStableUUID = () => {
  const uuid = localStorage.getItem('ocd_uid') ?? uuidv4()
  localStorage.setItem('ocd_uid', uuid)

  return uuid
}

const useStableUUID = () => {
  return getStableUUID()
}

export default useStableUUID
