/**
 * A collection of analytics handlers that are registered and managed.
 * Each handler in this array represents an analytics service (e.g., Firebase, Segment, Pendo)
 * that provides specific functionality such as logging events, setting user IDs, etc.
 * Handlers are objects that must implement methods that correspond to the analytics actions
 * to be executed (e.g., logEvent, identify).
 */
const analyticsHandlers = []

/**
 * Registers an analytics handler if a specified condition is true.
 * @param {Object} handler - The analytics handler to register.
 * @param {boolean} isEnabled - Condition that determines if the handler should be registered.
 */
export function registerAnalyticsHandler(handler, isEnabled) {
  if (isEnabled) {
    analyticsHandlers.push(handler)
  }
}

/**
 * Executes a specified method on all registered analytics handlers.
 * @param {string} methodName - The name of the method to execute.
 * @param {Array} args - Arguments to pass to the method.
 */
export function executeHandlers(methodName, args) {
  analyticsHandlers.forEach((handler) => {
    try {
      if (typeof handler[methodName] === 'function') {
        handler[methodName](...args)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error executing ${methodName} in ${handler.name}:`, error)
    }
  })
}

const analytics = {
  /**
   * Logs an event across all registered analytics handlers.
   * @param {string} name - Event name.
   * @param {Object} params - Event parameters.
   * @param {Object} options - Additional options.
   */
  logEvent(name, params, options) {
    executeHandlers('logEvent', [name, params, options])
  },
  /**
   * Sets user ID and associated properties across all registered analytics handlers.
   * @param {string} id - The user ID.
   * @param {Object} profileProperties - Profile properties.
   * @param {Object} groupProperties - Group properties.
   */
  identify(id, { profileProperties, groupProperties } = {}) {
    executeHandlers('identify', [id, { profileProperties, groupProperties }])
  },
  /**
   * Sets properties for the user across all registered analytics handlers.
   * @param {Object} profileProperties - Profile properties.
   * @param {Object} groupProperties - Group properties.
   * @param {Object} options - Additional options.
   */
  setProperties({ profileProperties, groupProperties } = {}, options) {
    executeHandlers('setProperties', [{ profileProperties, groupProperties }, options])
  },
  /**
   * Resets the session in all registered analytics handlers that support it.
   */
  reset() {
    executeHandlers('reset', [])
  }
}

export default analytics
