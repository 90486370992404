import { Box } from '@oneclickdata/components'
import React from 'react'

export function PageContainer({ children, ...props }) {
  return (
    <Box maxWidth="1148px" mx="auto" width="100%" {...props}>
      {children}
    </Box>
  )
}
