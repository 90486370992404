import { API, Auth } from 'aws-amplify'

const API_NAME = 'occapi'
const BASE_PATH = '/v2/projects'

export const addUpgrades = async ({ upgrades = [], patches = {}, projectNumber }) => {
  await Auth.currentCredentials()
  const apiResponse = await API.patch(API_NAME, `${BASE_PATH}/${projectNumber}`, {
    body: { action: 'apply-upgrades', upgrades, patches }
  })

  return apiResponse
}

export const userFlags = {
  update: async (projectNumber, updates = {}) => {
    await Auth.currentCredentials()
    const apiResponse = await API.patch(API_NAME, `${BASE_PATH}/${projectNumber}/user-flags`, {
      body: updates
    })

    return apiResponse
  }
}
