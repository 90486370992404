import { useProfile } from '../queryHooks'
import { useLimits } from './useLimits'

export const useAvailability = () => {
  const { status, data: profile, refetch, ...rest } = useProfile()
  const limits = useLimits()

  const availability = {}
  let isExpired

  if (status !== 'loading' && limits) {
    const { team, subscriptions = [] } = profile

    if (!subscriptions.length)
      return {
        ...rest,
        refetch,
        status,
        data: { ...availability, isExpired }
      }

    const subscription = subscriptions && subscriptions[0]

    isExpired = subscription?.is_expired

    const { type: subscriptionType } = subscription

    const locationKey = subscriptionType === 'trial-plan' ? 'free' : 'monthly'
    const allocationObject = limits[locationKey]

    const countsObject = (team ?? profile)?.counts
    try {
      Object.keys(allocationObject).forEach((key) => {
        const allocation = allocationObject?.[key]?.quantity
        if (typeof allocation === 'undefined') return
        // handle code_reports as jurisdiction for tech debt
        if (key === 'code_reports') {
          const used = countsObject[locationKey]?.[key]?.quantity ?? countsObject[locationKey][key]

          let available = allocation - used
          if (available < 0 || isExpired) {
            available = 0
          }

          availability.quick_view = {
            allocation,
            used,
            available
          }
        } else {
          const usedPath = `upgrades:counts:monthly:${key}:quantity_used`
          const used = (team ?? profile)[usedPath] ?? 0

          let available = allocation - used
          if (available < 0 || isExpired) {
            available = 0
          }

          availability[key] = {
            allocation,
            used,
            available
          }
        }
      })
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.log(`error: ${JSON.stringify(ex, null, 2)}`)
    }
  }

  return {
    ...rest,
    refetch,
    status,
    data: { ...availability, isExpired }
  }
}
