import { Honeybadger } from '@honeybadger-io/react'

export const setup = ({ userId, email, appSource } = {}) => {
  Honeybadger.setContext({
    userId,
    email,
    platform: appSource || 'Web'
  })
}

export const reset = () => {
  Honeybadger.resetContext()
}
