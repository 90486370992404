/* eslint-disable no-underscore-dangle */
import { Auth } from 'aws-amplify'
import * as AWS from 'aws-sdk'

/**
 * Provide methods to use IdentityDatasets
 */
class DatasetsClass {
  /**
   * @param {Object} config - Configuration of the Storage
   */
  constructor(config) {
    this.configure(config)
  }

  /**
   * Configure Storage part with aws configuration
   * @param {Object} config - Configuration of the Storage
   * @return {Object} - Current configuration
   */
  configure(config) {
    const conf = config ? config.Datasets || config : {}

    this._config = { ...this._config, ...conf }

    return this._config
  }

  /**
   * load a key from Federated Identity dataset
   * @param {String} key - key of the object
   * @param {Object} [options] - { dataset : string (default: user_data) }
   * @return {Promise} - A promise resolves to dataset contents on success
   */
  async get(key, options) {
    const credentialsOK = await this._ensureCredentials()
    if (!credentialsOK) return Promise.reject('No credentials')

    const opt = { ...this._config, ...options }
    const { dataset = 'user_data' } = opt
    const client = this._createClient()

    const params = {
      DatasetName: dataset /* required */,
      IdentityId: opt.credentials.identityId /* required */,
      IdentityPoolId: opt.IdentityPoolId /* required */
    }

    return new Promise((resolve, reject) => {
      client.listRecords(params, (err, data) => {
        if (err) reject(err)
        else {
          const { SyncSessionToken } = data
          const record = data.Records.find(datum => datum.Key === key)
          const { Value: value = '', SyncCount = 0 } = record || {}

          resolve({ key, value, SyncCount, SyncSessionToken })
        }
      })
    })
  }

  /**
   * Put a file in S3 bucket specified to configure method
   * @param {Stirng} key - key of the object
   * @param {Object} object - Data to be put in Federated Identity Dataset
   * @param {Object} options - { dataset: string (default: user_data) }
   * @return {Promise} - promise resolves to object on success
   */
  async put(key, obj, options) {
    if (typeof key !== 'string' || key === '') return Promise.reject('Invalid Key.')

    const value = typeof obj === 'object' ? JSON.stringify(obj) : obj ? obj.toString() : undefined

    const credentialsOK = await this._ensureCredentials()
    if (!credentialsOK) return Promise.reject('No credentials')

    const opt = { ...this._config, ...options }
    const { dataset = 'user_data' } = opt

    const client = this._createClient()

    try {
      const current = await this.get(key, opt)
      const params = {
        DatasetName: dataset /* required */,
        IdentityId: opt.credentials.identityId /* required */,
        IdentityPoolId: opt.IdentityPoolId /* required */,
        SyncSessionToken: current.SyncSessionToken /* required */,
        RecordPatches: [
          {
            Key: key,
            Op: opt.Op || 'replace',
            SyncCount: current.SyncCount,
            Value: value
          }
        ]
      }
      return new Promise((resolve, reject) => {
        client.updateRecords(params, (err) => {
          if (err) reject(err)
          resolve({ key, value })
        })
      })
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * Remove the object for specified key
   * @param {String} key - key of the object
   * @param {Object} [options] - { level : private|public }
   * @return {Promise} - Promise resolves upon successful removal of the object
   */
  async remove(key, options) {
    return this.put(key, undefined, { ...options, Op: 'remove' })
  }

  /**
   * @private
   */
  _ensureCredentials() {
    const conf = this._config
    if (conf.credentials && conf.credentials.identityId) {
      return Promise.resolve(true)
    }

    return Auth.currentCredentials()
      .then((credentials) => {
        const cred = Auth.essentialCredentials(credentials)
        conf.credentials = cred
        return true
      })
      .catch(() => false)
  }

  /**
   * @private
   */
  _createClient() {
    return new AWS.CognitoSync(this._config)
  }
}

export default DatasetsClass
