import { BaseModal } from '@oneclickdata/components'
import React from 'react'
import './index.css'

const ErrorModal = ({ dismiss = () => null, tryAgainButtonText, helpButtonText, handleHelp, primaryText, secondaryText, xClick, variant }) => {
  return (
    <BaseModal
      isOpen
      onClose={xClick}
      dismiss={xClick}
      title={primaryText}
      description={secondaryText}
      primaryActionText={tryAgainButtonText}
      primaryAction={dismiss}
      secondaryActionText={helpButtonText}
      secondaryAction={handleHelp}
      variant={variant}
    />
  )
}

export default ErrorModal
