import NewProject from './newProject'
import WithIcon from './withIcon'
import LargePlus from './largePlus'
import LargeDone from './largeDone'
import LargeUserMenu from './largeUserMenu'
import FloatingNew from './floatingNew'
import Flashy from './flashy'
import Blink from './blink'
import Primary from './primary'
import SmallRadius from './SmallRadius'

const Buttons = {
  NewProject,
  WithIcon,
  LargePlus,
  LargeDone,
  LargeUserMenu,
  FloatingNew,
  Flashy,
  Blink,
  Primary,
  SmallRadius
}

export default Buttons

export const Plus = require('../../images/icons/largeSkinnyPlus.svg')
