export const ROUTES_NAMES = {
  splash: { name: 'Splash', route: '/' },
  legacy: { name: 'Legacy', route: '/main' },
  signup: { name: 'Signup', route: '/signup' },
  acceptTos: {
    name: 'Accept Terms of Service',
    route: '/accept-terms-of-service'
  },
  introduceYourself: {
    name: 'IntroduceYourself',
    route: '/introduce-yourself'
  },
  confirmSignup: { name: 'Confirm Signup', route: '/confirm-signup' },
  forgotPassword: { name: 'Forgot Password', route: '/forgot-password' },
  login: { name: 'Login', route: '/login' },
  enterpriselogin: { name: 'Enterprise Login', route: '/enterpriselogin' },
  app: { name: 'App', route: '/' },
  reportRequest: { name: 'Report Request', route: '/reportRequest' },
  report: { name: 'Report', route: '/report' },
  dashboard: { name: 'Dashboard', route: '/dashboard' },
  dashboardNew: { name: 'Dashboard New', route: '/dashboard/new' },
  integrations: { name: 'Integrations', route: '/integrations' },
  subscription: { name: 'Subscription', route: '/subscription' },
  mandatoryProfile: { name: 'Mandatory Profile', route: '/mandatory-profile' },
  profile: { name: 'Profile', route: '/profile' },
  plansPurchase: { name: 'Plans Purchase', route: '/plans/purchase' },
  plans: { name: 'Explore Plans', route: '/plans' },
  businessPlans: { name: 'BusinessPlans', route: '/business_pricing' },
  addressLookup: { name: 'Address Lookup', route: '/addresslookup' },
  myTeam: { name: 'My Team', route: '/myTeam' },
  editTeam: { name: 'Edit Team', route: '/editteam' },
  editTeamMember: { name: 'Edit Team Member', route: '/editteammember' },
  feedback: { name: 'Feedback', route: '/feedback' },
  manufacturerSelection: {
    name: 'Manufacturer Selection',
    route: '/manufacturer-selection'
  },
  permitFee: { name: 'Permit Fee', route: '/permit-fee' },
  search: { name: 'Search', route: '/search' },
  propertyType: { name: 'Property Type', route: '/propertytype' },
  singlePurchase: { name: 'Single Project Purchase', route: '/singlepurchase' },
  calculators: { name: 'Tools - Calculators', route: '/tools' },
  profileFull: { name: 'Profile', route: '/profile/full' },
  profileVerifyPhone: { name: 'Verify Phone', route: '/profile/verifyphone' },
  profileUpdateEmail: { name: 'Update Email', route: '/profile/email' },
  profileUpdatePassword: {
    name: 'Update Password',
    route: '/profile/password'
  },
  profileCompanyDetails: {
    name: 'Update Password',
    route: '/profile/mobilecompanydetails'
  },
  tools: {
    name: 'Tools',
    route: '/tools'
  },
  wasteCalculator: {
    name: 'Waste Calculator',
    route: '/tools/waste'
  }
}
