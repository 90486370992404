import { API, Auth } from 'aws-amplify'

const BASE_PATH = '/permit-fees'

const manager = {
  getFeeDetails: async (details) => {
    await Auth.currentCredentials()
    const { address, projectVal, materialCost, materialType, roofSize, propertyType, projectNumber } = details
    try {
      const apiResponse = await API.post('occapi', BASE_PATH, {
        body: {
          address,
          estimated_project_valuation: projectVal,
          estimated_material_cost: materialCost,
          roof_material_type: materialType,
          roof_size: roofSize,
          property_type: propertyType,
          project_number: projectNumber
        }
      })

      return apiResponse
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

export default manager
