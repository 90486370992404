import React from 'react'
import { Text } from 'react-native'
import { fonts } from '../CommonStyles'

const BrandText = ({ children, style, ...props }) => (
  <Text style={[{ ...fonts.normal }, style]} {...props}>
    {children}
  </Text>
)

export default BrandText
