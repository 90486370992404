import { useEffect } from 'react'

function useSearchSessionStorage() {
  useEffect(() => {
    let entries = []
    if (typeof URLSearchParams !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search)
      entries = [...searchParams.entries()]
      entries.forEach((entry) => {
        sessionStorage.setItem(...entry)
      })
    }

    return () => {
      entries.forEach(entry => sessionStorage.removeItem(entry[0]))
    }
  }, [])
}

export default useSearchSessionStorage
