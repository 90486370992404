import { Box, useBreakpointValue, View } from '@oneclickdata/components'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Text from '../../native/components/brand/Text'
import LargeHeader from '../LargeHeader'
import MobileHeader from '../MobileHeader'
import { PageContainer } from '../PageContainer'

const DashboardHeader = ({ email, groups, name, logout, missingProfession }) => {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false
  })
  const loggedIn = useSelector(({ auth }) => auth.loggedIn)

  return (
    <View>
      <PageContainer zIndex={99}>
        {loggedIn && (
          <>
            <Box zIndex={1}>
              {isMobile ? (
                <MobileHeader name={name} onLogout={logout} email={email} showAdmin={groups.includes('admin')} />
              ) : (
                <LargeHeader name={name} onLogout={logout} showAdmin={groups.includes('admin')} />
              )}
              {missingProfession && (
                <Box px="$4">
                  <Text>
                    We noticed your profession has not been filled out on your user profile, <Link to="/profile/full">what do you do?</Link>
                  </Text>
                </Box>
              )}
            </Box>
          </>
        )}
      </PageContainer>
    </View>
  )
}

export default DashboardHeader
