/* eslint-disable react/destructuring-assignment */
import React from 'react'
import './flashy.css'

class Flashy extends React.PureComponent {
  dim = () => {
    const { div } = this

    const styles = this.props.customAnimation
      ? this.props.customAnimation.start
      : {
          opacity: '.4'
        }

    div.classList.add('active')
    applyStylesToDOMNode({
      domNode: div,
      styles
    })
  }

  endAnimation = () => {
    const { div } = this

    const styles = this.props.customAnimation
      ? this.props.customAnimation.during
      : {
          opacity: '1',
          transition: 'opacity 300ms ease-out'
        }

    div.classList.add('animating')

    applyStylesToDOMNode({
      domNode: div,
      styles
    })

    const duration = this.props.customAnimation ? this.props.customAnimation.duration : 300

    setTimeout(() => {
      div.classList.remove('active')
      div.classList.remove('animating')

      // eslint-disable-next-line no-shadow
      const styles = this.props.customAnimation
        ? this.props.customAnimation.after
        : {
            opacity: '1',
            transition: 'none'
          }

      applyStylesToDOMNode({ domNode: div, styles })
    }, duration)
  }

  render() {
    const { enlarge, disabled, onClick, className, style, whenFollowed, ...props } = this.props

    return (
      <div
        className={`oneclick-button-flashy${enlarge ? ' oneclick-button-flashy-enlarge' : ''}${disabled ? ' oneclick-button-flash-disabled' : ''} ${className || ''}`}
        style={style}
        ref={div => (this.div = div)}
        onTouchStart={!disabled ? this.dim : undefined}
        onMouseDown={!disabled ? this.dim : undefined}
        onMouseLeave={!disabled ? this.endAnimation : undefined}
        onMouseUp={!disabled ? this.endAnimation : undefined}
        onTouchEnd={!disabled ? this.endAnimation : undefined}
        onTouchMove={!disabled ? this.endAnimation : undefined}
        onClick={
          !disabled
            ? () => {
                whenFollowed && whenFollowed()
                onClick && onClick()
              }
            : undefined
        }
        {...props}
      />
    )
  }
}

export default Flashy

const applyStylesToDOMNode = ({ domNode, styles }) => {
  Object.keys(styles).forEach((key) => {
    domNode.style.setProperty(hyphenate(key), styles[key])
  })
}

const memoizeString = (fn) => {
  const cache = {}

  return function (str) {
    if (!cache[str]) {
      cache[str] = fn(str)
    }
    return cache[str]
  }
}

const hyphenate = memoizeString(str => str.replace(/([A-Z])/g, '-$1').toLowerCase())
