import { API, Auth } from 'aws-amplify'

const BASE_PATH = '/codeReports/config'

const manager = {
  get: async (variable) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/${variable}`)

    return apiResponse
  }
}

export default manager
