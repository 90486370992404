const brand = {
  color: {
    highlightBlue: '#64ABFF',
    brightBlue: '#64abff',
    darkBlue: '#5D7B9D',
    fadeBlue: '#5D7B9D',
    red: '#F70003',
    darkGreen: '#009200',
    black: '#000',
    royalBlue: '#25539E',
    yellow: '#FFB829',
    whiteGray: '#B0B0B0',
    tabBarTint: '#4D4D4D'
  }
}

export const fonts = {
  normal: {
    fontFamily: 'Barlow',
    fontSize: 17,
    color: 'white',
    backgroundColor: 'transparent',
    textAlign: 'center'
  }
}

export default { brand }
