import { API, Auth } from 'aws-amplify'

const BASE_PATH = '/products'

const manager = {
  getSkus: async (product) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/${product}`)

    return apiResponse
  },

  list: async (list) => {
    try {
      const apiResponse = await API.post('occapi', `${BASE_PATH}/list-lookup`, {
        body: list
      })

      return apiResponse
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message)

      return []
    }
  },

  getSku: async ({ type, sku }) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/${type}/${sku}`)

    return apiResponse
  }
}

export default manager
