import awsmobile from './aws-exports'
import { npm_package_version } from './_version.json'
/*

  The following logic was previously in ./aws-exports... is it still relevant?!?!?
  I do not believe so... leaving here for a moment while I verify all works well without it.
*/
// eslint-disable-next-line no-unused-vars
const env = process.env.REACT_APP_ENV

const logicIndex = awsmobile.aws_cloud_logic_custom.findIndex(item => item.name === 'occapi')
awsmobile.aws_cloud_logic_custom[logicIndex].custom_header = () => ({
  'X-OneClickCode-App-Version': npm_package_version,
  'X-OneClickCode-Platform-Type': 'web'
})

const { origin } = window.location

const redirectSignIn = origin.includes('localhost') ? `${origin}/` : process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNIN
const redirectSignOut = origin.includes('localhost') ? `${origin}/idpauth` : process.env.REACT_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT

export default {
  ...awsmobile,
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_REGION,
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOLS_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
      scope: ['openid', 'profile', 'email'],
      redirectSignIn,
      redirectSignOut,
      responseType: 'code' // or 'token'
    }
  },
  Analytics: {
    disabled: true
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_USERCONTENT_BUCKET,
      region: process.env.REACT_APP_AWS_REGION
    }
  }
}
