import responsiveImage from './responsiveImage'

export const Close = responsiveImage('', [require('../../images/icons/modalX.png'), require('../../images/icons/modalX@2x.png'), require('../../images/icons/modalX@3x.png')])

export const TitledX = responsiveImage('', [
  require('../../images/icons/titledModalX.png'),
  require('../../images/icons/titledModalX@2x.png'),
  require('../../images/icons/titledModalX@3x.png')
])

export const BlueShare = responsiveImage('', [
  require('../../images/icons/blueShare.png'),
  require('../../images/icons/blueShare.png'),
  require('../../images/icons/blueShare.png')
])

export const CloseWhite = responsiveImage('', [
  require('../../images/icons/modalWhite.png'),
  require('../../images/icons/modalWhite@2x.png'),
  require('../../images/icons/modalWhite@3x.png')
])
