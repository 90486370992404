import { API } from 'aws-amplify'

const API_NAME = 'occapi'
const BASE_PATH = '/public'

/**
 *
 * @param {string} appleId - response.user string provided by Apple API during first and subsequent Sign in with Apple
 * @param {string} email - response.email string provided by Apple API only during first Sign in with Apple
 * @returns {promise}
 */
export const storeAppleIDEmail = async (appleId, email) => {
  const apiResponse = await API.post(API_NAME, `${BASE_PATH}/idassociations`, {
    body: {
      data: {
        type: 'idassociations',
        id: appleId,
        attributes: { email }
      }
    }
  })

  return apiResponse.data.success
}

/**
 *
 * @param {string} microsoftId - response.user string provided by Microsoft API during first and subsequent Sign in with Microsoft
 * @param {string} email - response.email string provided by Microsoft API only during first Sign in with Microsoft
 * @returns {promise}
 */
export const storeMicrosoftIDEmail = async (microsoftId, email) => {
  const apiResponse = await API.post(API_NAME, `${BASE_PATH}/idassociations`, {
    body: {
      data: {
        type: 'idassociations',
        id: microsoftId,
        attributes: { email }
      }
    }
  })

  return apiResponse.data.success
}

/**
 *
 * @param {string} appleId response.user string provided by Apple API during first and subsequent Sign in with Apple
 * @returns {promise<string|Error>} resolves to email string
 */
export const lookupEmailbyAppleID = async (appleId) => {
  const apiResponse = await API.get(API_NAME, `${BASE_PATH}/idassociations/${appleId}`)

  const { email } = apiResponse.data.attributes

  return email
}

/**
 *
 * @param {string} microsoftId response.user string provided by Microsoft API during first and subsequent Sign in with Microsoft
 * @returns {promise<string|Error>} resolves to email string
 */
export const lookupEmailbyMicrosoftID = async (microsoftId) => {
  const apiResponse = await API.get(API_NAME, `${BASE_PATH}/idassociations/${microsoftId}`)
  const { email } = apiResponse.data.attributes

  return email
}
