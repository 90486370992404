import { HStack, Text, VStack } from '@oneclickdata/components'
import React from 'react'
import { useTeam } from '../../customHooks/useTeam'
import { useProfile } from '../../queryHooks'

const ProfileDetails = ({ email, userId, team_id }) => (
  <VStack>
    <TextDetail label="Email" value={email} />
    <TextDetail label="UserId" value={userId} />
    {team_id && <TextDetail label="TeamId" value={team_id} />}
  </VStack>
)

const SubscriptionDetails = ({ subscription, role, isOwner, team_id }) => (
  <VStack>
    <TextDetail label={`TYPE: ${subscription?.type} SKU`} value={subscription?.sku} />
    <TextDetail value={`${subscription?.billingPeriod} | ${subscription?.option} | ${subscription?.is_expired ? 'expired' : 'active'}`} />
    {team_id && <TextDetail value={isOwner ? 'Owner' : role} />}
  </VStack>
)

const TextDetail = ({ label, value }) => (
  <VStack>
    <Text fontFamily="monospace" color="black" fontSize="10px" bold>
      {label ? `${label}: ` : ''}
      {value}
    </Text>
  </VStack>
)

const PausedSubscriptionDetails = ({ subscription }) => {
  const { paused_at, pause_active_at, resumes_at, details } = subscription
  const test_clock_details = details?.test_clock_details
  const pausedDates = {
    paused_at: paused_at ? new Date(paused_at).toLocaleString() : null,
    pause_active_at: pause_active_at ? new Date(pause_active_at).toLocaleString() : null,
    resumes_at: resumes_at ? new Date(resumes_at).toLocaleString() : null
  }

  if (!paused_at) return null

  return (
    <HStack mr="$8" space="sm" justifyContent="space-between">
      <VStack>
        <TextDetail label="Paused at" value={pausedDates.paused_at} />
        <TextDetail label="Usable until" value={pausedDates.pause_active_at} />
      </VStack>
      <VStack>
        <TextDetail label="Resumes at" value={pausedDates.resumes_at} />
        {test_clock_details?.frozen_time && <TextDetail label="Stripe Sim Time" value={new Date(test_clock_details?.frozen_time * 1000).toLocaleString()} />}
      </VStack>
    </HStack>
  )
}

const UserDebug = () => {
  const team = useTeam()
  const { data: profile } = useProfile()
  const { email, userId, team_id, subscriptions } = profile || {}
  const activeSubscription = subscriptions?.[0]
  const role = team?.members.find(x => x.id === userId)?.role
  const isOwner = role === 'owner' && userId === team?.admin_id
  const showUserDebug = localStorage.getItem('showUserDebug') === 'true'
  if (!showUserDebug) {
    return null
  }

  return (
    <VStack p="$3" backgroundColor="$red200">
      <HStack mr="$8" space="sm">
        <ProfileDetails email={email} userId={userId} team_id={team_id} />
        <SubscriptionDetails subscription={activeSubscription} role={role} isOwner={isOwner} team_id={team_id} />
      </HStack>
      <PausedSubscriptionDetails subscription={activeSubscription} />
    </VStack>
  )
}

export default UserDebug
