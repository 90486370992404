import { Box, HStack, InfoCircleOutlinedIcon } from '@oneclickdata/components'
import React, { forwardRef, useContext } from 'react'
import { createPortal } from 'react-dom'
import { PageContainer } from '../PageContainer'

export const AlertBoxContext = React.createContext()

export function AlertBoxProvider({ children }) {
  const alertBoxRef = React.useRef(null)

  return <AlertBoxContext.Provider value={{ ref: alertBoxRef }}>{children}</AlertBoxContext.Provider>
}

export function useAlertBoxContext() {
  const context = useContext(AlertBoxContext)
  if (!context) {
    throw new Error('useAlertBoxRef must be used within a AlertBoxProvider')
  }

  return context
}

export function AlertBoxOutput(props) {
  const alertBox = useAlertBoxContext()

  return <Box ref={alertBox.ref} {...props} />
}

export function AlertPortal({ children, sticky }) {
  const alertBox = useAlertBoxContext()
  if (!alertBox?.ref?.current) {
    return null
  }
  if (sticky) {
    alertBox.ref.current.style.position = 'sticky'
  }

  return createPortal(children, alertBox.ref.current)
}

const Alert = forwardRef(({ children }, ref) => {
  return (
    <Box bg="$cyan700" ref={ref}>
      <PageContainer>
        <HStack px="$4" py="$2" minH="$12" gap="$4" alignItems="center">
          <InfoCircleOutlinedIcon size="lg" minWidth={20} color="white" />
          {children}
        </HStack>
      </PageContainer>
    </Box>
  )
})

export { Alert }
