import glamorous from 'glamorous'
import React, { useRef, useState } from 'react'
import { brand } from '../../utils/CommonStyles'
import { TestIDs } from '../../utils/testing'

const Div = glamorous.div({
  width: 125,
  height: 125,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: brand.color.yellow,
  borderRadius: '50%',
  fontSize: 60,
  fontWeight: '600',
  overflow: 'hidden',
  textTransform: 'uppercase'
})

const Avatar = ({ name = '', url, style, style2, isActive, onChange }) => {
  const [hover, setHover] = useState(false)
  const fileInput = useRef(null)
  const selectFile = () => {
    fileInput.current.click()
  }
  const [img, setImg] = useState()

  const onImageChange = (e) => {
    const [file] = e.target.files
    setImg(URL.createObjectURL(file))
    onChange(file)
  }

  return (
    <Div
      style={style}
      id="team-badge-img"
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}>
      <input type="file" id="file" ref={fileInput} style={{ display: 'none' }} onChange={onImageChange} />
      {!url && !hover ? name.charAt(0) : ''}
      {url && (!hover || !isActive) && <img src={isActive ? img || url : url} alt="" data-testid={TestIDs.avatarImage} />}
      {hover && isActive && <div style={style2} onClick={selectFile} />}
    </Div>
  )
}

export default Avatar
