import React from 'react'

export const TestIDs = {
  avatarImage: 'avatar-image',
  signInWithAppleButton: 'sing-in-with-apple-button',
  loginEmailInput: 'login-email-input',
  loginPasswordInput: 'login-password-input',
  rightCaretImage: 'right-caret-image',
  occupationSlideContainer: 'occupation-slide-container',
  passwordSlideContainer: 'password-slide-container',
  occupationChoiceTouchable: 'occupation-choice-touchable',
  referenceChoiceTouchable: 'reference-choice-touchable',
  labeledTextInput: 'labeled-text-input',
  accountToggleButton: 'account-toggle-button',
  passwordInput: 'password-input',
  loginButton: 'login-button',
  signoutButton: 'signout-button',
  subscriptionButton: 'subscription-button',
  suggestionAddress: 'suggestion-address',
  suggestionAddressContainer: 'suggestion-address-container',
  newReportPropertyTypeButtonsContainer: 'newReport-propertyType-buttons-container',
  newReportPropertyTypeNextButton: 'new-report-property-type-next-button',
  reportControlButton: 'report-control-button',
  stickyMenuButton: 'stickyMenuButton',
  reportItem: 'report-item',
  stickyMenuContainer: 'sticky-menu-container-div',
  stickyMenuCloseButton: 'sticky-menu-close-button',
  stickyMenuCodeDetailButton: 'sticky-menu-code-detail-button',
  stickyMenuHailStormHistoryButton: 'sticky-menu-hail-storm-history-button',
  stickyMenuBuildingDesignCriteriaButton: 'sticky-menu-building-design-criteria-button',
  stickyMenuManufacturerRequirementsButton: 'sticky-menu-manufacturer-requirements-button',
  stickyMenuPermitCalculatorButton: 'sticky-menu-permit-calculator-button',
  projectValueInput: 'project-value-input',
  materialCostInput: 'material-cost-input',
  roofSizeSQInput: 'roof-size-sq-input',
  materialTypeCaretDown: 'material-type-caret-down-img',
  shingleSelectorCaretDown: 'material-type-caret-down-img',
  touchableMFGElement: 'mfg-element-touchable',
  reportCountyContainer: 'report-county-container',
  tabComponent: 'tabs-component',
  paymentModalContainer: 'payment-modal-container',
  paymentAddress: 'payment-address-input',
  paymentAddressCity: 'payment-address-city-input',
  paymentAddressZipCode: 'payment-address-zipcode-input',
  paymentErrorContainer: 'payment-error-container',
  viewPlans: 'view-plans-touchable',
  planCard: 'plan-card-touchable',
  cvcContainer: 'cvc-container',
  creditCardNumberContainer: 'credit-card-number-container',
  creditExpirationContainer: 'credit-expiration-container',
  freePlanStepssuccessOverlay: 'free-plan-steps-overlay',
  modalCloseSuccessButton: 'modal-close-button',
  images: {
    rightCaret: 'right-caret-image',
    feedbackBlue: 'feedback-blue-image',
    skinnyCaretWhite: 'skinny-caret-white-image',
    caretBlue: 'caret-blue-image',
    greenCheckmark: 'green-checkmark-image',
    closeXYellow: 'close-x-image',
    whitePlus: 'white-plus-image',
    xClose: 'x-close-image',
    blueCaretdown: 'blue-caret-down-image',
    blueCaretUp: 'blue-caret-up-image',
    greyArrowDown: 'grey-arrow-down-image',
    location: 'location-image',
    locationGray: 'location-gray-image',
    statesBlue: 'states-blue-image',
    statesGray: 'states-gray-image',
    checkmarkYellow: 'checkmar-yellow-image',
    tooltipInfo: 'tooltip-info-image'
  }
}

export const ChildTestID = ({ testID }) => <div data-testid={testID} />
