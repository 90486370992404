import { API, Auth } from 'aws-amplify'
import mockedTeam from './mock-team.json'

window.api = API
const BASE_PATH = '/teams'

const manager = {
  get: async (id) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/${id}`)

    return apiResponse
  },

  getMembers: async (id) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/${id}/members`)

    return apiResponse
  },

  searchTeamMocked: async (teamId, { pageNumber, limit, sortBy, sortOrder, term = '' }) => {
    const allRecords = mockedTeam.members
    let result = allRecords
    if (term !== '') {
      result = allRecords.filter((member) => {
        return member.email.toLowerCase().includes(term.toLowerCase()) || member.displayName.toLowerCase().includes(term.toLowerCase())
      })
    }
    if (sortBy !== '') {
      result = result.sort((a, b) => {
        if (a[sortBy] > b[sortBy]) {
          return sortOrder === 'asc' ? 1 : -1
        }
        if (a[sortBy] < b[sortBy]) {
          return sortOrder === 'asc' ? -1 : 1
        }

        return 0
      })
    }
    const pageStart = pageNumber * limit
    const page = result.slice(pageStart, pageStart + limit)

    return { Count: result.length, Items: page.map(member => ({ ...member, teamId })), id: 'mocked' }
  },

  searchTeam: async (team_id, { term = '', sortBy = 'createdAt', sortOrder = 'desc', pageNumber = 0, limit = 5, filterBy = {} }) => {
    await Auth.currentCredentials()
    const filters = Object.fromEntries(
      Object.entries(filterBy)
        .filter(([, value]) => {
          return Array.isArray(value) && value.length > 0
        })
        .map(([key, value]) => {
          return [key, encodeURIComponent(value.join(','))]
        })
    )
    const hasFilters = Object.keys(filters).length > 0
    const params = new URLSearchParams({
      ...(term && { term }),
      sortBy,
      sortOrder,
      page: pageNumber,
      limit,
      ...(hasFilters && filters)
    })
    const apiResponse = await API.get('occapi', `${BASE_PATH}/${team_id}/members/search?${params.toString()}`)

    return apiResponse
  },

  updateTeamName: async (id, name) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/${id}`, { body: { name } })

    return apiResponse
  },

  addMember: async (id, emails, role) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/${id}/members`, { body: { emails, role } })

    return apiResponse
  },

  removeMember: async (teamId, memberId) => {
    await Auth.currentCredentials()
    const apiResponse = await API.del('occapi', `${BASE_PATH}/${teamId}/members`, { body: { member_id: memberId } })

    return apiResponse
  },

  uploadTeamLogo: async (teamId, imgKey) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/${teamId}/team-logo`, { body: { imgKey } })

    return apiResponse
  },

  searchReports: async ({ team_id, term }) => {
    await Auth.currentCredentials()
    const apiResponse = await API.get('occapi', `${BASE_PATH}/${team_id}/reports?term=${term}`)

    return apiResponse
  },

  checkTeamInvite: async (email, resend) => {
    await Auth.currentCredentials()
    try {
      const apiResponse = await API.get('occapi', `${BASE_PATH}/invites?email=${email}&resend=${resend}`)

      return apiResponse
    } catch (e) {
      return Promise.reject(e)
    }
  },

  handleTeamInviteDecision: async (teamId, details) => {
    await Auth.currentCredentials()
    // remove with new handling of team tables
    if (details?.invite?.teamMembers) details.invite.teamMembers = []
    try {
      const apiResponse = await API.post('occapi', `${BASE_PATH}/${teamId}/members/invites`, { body: { details } })

      return apiResponse
    } catch (e) {
      return Promise.reject(e)
    }
  },

  changeTeamOwner: async (teamId, newAdminId) => {
    await Auth.currentCredentials()
    const apiResponse = await API.post('occapi', `${BASE_PATH}/${teamId}/members/changeowner`, { body: { newAdminId } })

    return apiResponse
  },

  changeMemberRole: async (teamId, memberId, newRole) => {
    await Auth.currentCredentials()
    // eslint-disable-next-line no-console
    console.log(`${BASE_PATH}/${teamId}/members/${memberId}/changerole`)
    const apiResponse = await API.post('occapi', `${BASE_PATH}/${teamId}/members/${memberId}/changerole`, { body: { newRole } })

    return apiResponse
  },

  getReportCount: async (teamId, requesterId) => {
    await Auth.currentCredentials()
    try {
      if (requesterId != null && requesterId.indexOf('@') > 0) throw new Error('invalid requester id or requester object to examine.')
      const apiResponse = await API.get('occapi', `${BASE_PATH}/${teamId}/projectCount?requester_id=${requesterId}`)

      return apiResponse
    } catch (e) {
      return { id: `${requesterId}`, Count: 0 }
    }
  }
}

export default manager
